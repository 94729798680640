import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Snackbar,
  Alert,
  Paper,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahPenarikan = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  var date = new Date();

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const [juals, setJuals] = useState([]);
  // Customer
  const [noPenarikan, setNoPenarikan] = useState(""); // I
  const [inputTglPenarikan, setInputTglPenarikan] = useState(date); // I
  const [noJual, setNoJual] = useState(""); // I

  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngFormatted, setTglAngFormatted] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  // Motor
  const [tipe, setTipe] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [nopol, setNopol] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [inputTglStnk, setInputTglStnk] = useState(new Date());
  // Biaya
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");
  const [tglJatuhTempoFormatted, setTglJatuhTempoFormatted] = useState("");
  const [sisaBulanPenarikan, setSisaBulanPenarikan] = useState(""); // I
  const [ketPenarikan, setKetPenarikan] = useState(""); // I

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchTermJual, setSearchTermJual] = useState("");
  const [openJual, setOpenJual] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTermJual(query);
  };

  const classes = useStyles();

  const handleClickOpenJual = () => {
    setOpenJual(true);
  };

  const handleCloseJual = () => {
    setOpenJual(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getPenarikanNextKode();
  }, []);

  useEffect(() => {
    getJual();
  }, [page, searchTermJual]);

  const getPenarikanNextKode = async () => {
    setLoading(true);
    const nextKodePenarikan = await axios.post(
      `${tempUrl}/penarikansNextKode`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setNoPenarikan(nextKodePenarikan.data);
    setLoading(false);
  };

  const getJual = async () => {
    const allJualsForPenarikan = await axios.post(
      `${tempUrl}/jualsForPenarikanPagination?search_query=${searchTermJual}&page=${page}&limit=${limit}`,
      {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    setJuals(allJualsForPenarikan.data.juals);
    setPage(allJualsForPenarikan.data.page);
    setPages(allJualsForPenarikan.data.totalPage);
    setRows(allJualsForPenarikan.data.totalRows);
  };

  const savePenarikan = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation =
      noPenarikan.length === 0 ||
      inputTglPenarikan === null ||
      noPenarikan.length === 0 ||
      namaStnk.length === 0 ||
      inputTglStnk === null;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);

        let tglPenarikan =
          inputTglPenarikan?.getFullYear() +
          "-" +
          (inputTglPenarikan?.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "-" +
          inputTglPenarikan?.getDate().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });

        // Find Jual
        const findJual = await axios.post(`${tempUrl}/jualByNoJual`, {
          noJual,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        // Find Angsuran
        const findAngsuran = await axios.post(`${tempUrl}/angsuransByNoJual`, {
          noJual,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        console.log(findAngsuran.data);

        console.log(
          `hargaSatuan: ${
            findJual.data.angModal *
            (findAngsuran.data.tenor - findAngsuran.data.bayarKe + 1)
          }`
        );

        let hargaSatuan =
          findJual.data.angModal *
          (findAngsuran.data.tenor - findAngsuran.data.bayarKe + 1);

        // Save Penarikan
        let savedPenarikan = await axios.post(`${tempUrl}/savePenarikan`, {
          noPenarikan,
          tglPenarikan,
          noJual,
          idJual: findJual.data._id,
          sisaBulanPenarikan,
          ketPenarikan,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        // Update Jual
        await axios.post(`${tempUrl}/updateJual/${findJual.data._id}`, {
          isTarik: true,
          noPenarikan,
          tglPenarikan,
          idPenarikan: savedPenarikan.data._id,
          ketPenarikan,
          sisaBulan: 0,
          sisaBulanPenarikan,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        // Update Titip
        await axios.post(`${tempUrl}/updateTitipByIdJual`, {
          idJual: findJual.data._id,
          isTarik: true,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        // Update Sweeping
        await axios.post(`${tempUrl}/updateSweepingByIdJual`, {
          idJual: findJual.data._id,
          isTarik: true,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        // Update Surat Penarikan
        await axios.post(`${tempUrl}/updateSuratPenarikanByIdJual`, {
          idJual: findJual.data._id,
          isTarik: true,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });

        const findTipe = await axios.post(`${tempUrl}/tipesByKode`, {
          kodeTipe: findJual.data.tipe,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });

        const pickedSupplier = await axios.post(`${tempUrl}/supplierByKode`, {
          kodeSupplier: setting.supplierPenarikan,
          id: user._id,
          token: user.token,
        });

        const { _id, ...otherDetails } = findJual.data;
        let daftarStokData = {
          ...otherDetails,
          nopol: `${otherDetails.nopol}.`,
          hargaSatuan,
          namaStnk,
          tglStnk: inputTglStnk,
          supplierId: pickedSupplier.data._id,
          kodeSupplier: pickedSupplier.data.kodeSupplier,
          namaSupplier: pickedSupplier.data.namaSupplier,
          namaTipe: findTipe.data.namaTipe,
          merk: findTipe.data.merk,
          noBeli: noPenarikan,
          tanggalBeli: tglPenarikan,
          isPost: false,
          ppnABeli: 0,
          totalBiayaPerawatan: 0,
          idPenarikan: savedPenarikan.data._id,
          noJual: "",
          tanggalJual: "",
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
          isMigrated: false,
        };
        // Save Daftar Stok
        const tempDaftarStok = await axios.post(
          `${tempUrl}/saveDaftarStok`,
          daftarStokData
        );

        // Find Angsuran Child
        const findAngsuranChild = await axios.post(
          `${tempUrl}/angsuransChild`,
          {
            noJual,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );

        let tempNo = findAngsuranChild.data._id;
        let dari = findJual.data.tenor;

        while (tempNo <= dari) {
          // Find Angsuran
          let findAngsuranChild = await axios.post(
            `${tempUrl}/angsuransFindChild`,
            {
              noJual,
              ke: tempNo,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            }
          );
          // Update Angsuran
          await axios.post(
            `${tempUrl}/updateAngsuranSuratPenarikan/${findAngsuran.data._id}`,
            {
              ...findAngsuranChild.data,
              angsuranKe: tempNo - 1,
              _id: tempNo,
              tglBayar: tglPenarikan,
              noKwitansi: noPenarikan,
              isPenarikan: true,
              tglUpdate: current_date,
              jamUpdate: current_time,
              userUpdate: user.username,
              id: user._id,
              token: user.token,
            }
          );
          tempNo++;
        }
        setLoading(false);
        navigate("/penarikan");
        getJual();
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Entry Penarikan
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={savePenarikan}>
          {/* Data Customer */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA CUSTOMER
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Penarikan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noPenarikan} disabled />
                        <Form.Control.Feedback type="invalid">
                          No. Penarikan harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Penarikan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={inputTglPenarikan}
                          onChange={(e) => {
                            setInputTglPenarikan(e);
                          }}
                          customInput={
                            <TextField
                              error={
                                error && inputTglPenarikan === null && true
                              }
                              helperText={
                                error &&
                                inputTglPenarikan === null &&
                                "Tgl. Penarikan harus diisi!"
                              }
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Jual <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={noJual}
                          required
                          placeholder="Pilih..."
                          onClick={() => handleClickOpenJual()}
                          onChange={(e) =>
                            setNoJual(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          No. Jual harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Telepon :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tlpRegister} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={namaRegister} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Alamat :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={almRegister} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Kelurahan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={kodeKelurahan} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Angsuran :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tglAngFormatted} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Motor */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA MOTOR
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tipe :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tipe} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        No. Rangka :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={noRangka} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tahun / Warna :
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control value={tahun} disabled />
                      </Col>
                      <Col sm={4}>
                        <Form.Control value={namaWarna} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nopol :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={nopol} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tanggal STNK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={inputTglStnk}
                          onChange={(e) => setInputTglStnk(e)}
                          customInput={
                            <TextField
                              error={error && inputTglStnk === null && true}
                              helperText={
                                error &&
                                inputTglStnk === null &&
                                "Dari Tanggal Stnk harus diisi!"
                              }
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Tgl. STNK harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Nama STNK <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={namaStnk}
                          required
                          onChange={(e) =>
                            setNamaStnk(e.target.value.toUpperCase())
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Nama STNK harus diisi!
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
            </Box>
          </Paper>

          {/* Data Penarikan */}
          <Paper elevation={6} sx={mainContainer}>
            <Typography variant="h5" sx={titleStyle} color="primary">
              DATA PENARIKAN
            </Typography>
            <Box sx={showDataContainer}>
              <Box sx={showDataWrapper}>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Tgl. Jatuh Tempo:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control value={tglJatuhTempoFormatted} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Sisa Bulan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={sisaBulanPenarikan.toLocaleString("en-US")}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={ketPenarikan}
                          onChange={(e) => {
                            setKetPenarikan(e.target.value.toUpperCase());
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Box>
              <Box sx={[showDataWrapper, secondWrapper]}></Box>
            </Box>
          </Paper>

          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/penarikan")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={spacingTop} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openJual}
        onClose={handleCloseJual}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data Jual`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <Form onSubmit={searchData}>
              <Box sx={searchBarContainer}>
                <SearchBar value={query} setSearchTerm={setQuery} />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disableElevation
                >
                  Cari
                </Button>
              </Box>
            </Form>
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Jual
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Nama Register
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Tanggal
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      No. Plat
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Alamat</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {juals.map((user, index) => (
                    <TableRow
                      key={user._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "&:hover": { bgcolor: Colors.grey300 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setNoJual(user.noJual);
                        setNamaRegister(user.namaRegister);
                        setAlmRegister(user.almRegister);
                        setTglAng(user.tglJatuhTempo);
                        setTglAngFormatted(formatDate(user.tglJatuhTempo));
                        setKodeKelurahan(
                          `${user.kodeKelurahan.kodeKelurahan} - ${user.kodeKelurahan.namaKelurahan}`
                        );
                        setSisaBulanPenarikan(user.sisaBulan);
                        setTlpRegister(user.tlpRegister);
                        setTipe(user.tipe);
                        setNoRangka(user.noRangka);
                        setNamaWarna(user.namaWarna);
                        setTahun(user.tahun);
                        setNopol(user.nopol);
                        setTglJatuhTempo(user.tglJatuhTempo);
                        setTglJatuhTempoFormatted(
                          formatDate(user.tglJatuhTempo)
                        );

                        handleCloseJual();
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {user.noJual}
                      </TableCell>
                      <TableCell>
                        {user.tenor - user.bayarKe !== 0 ? "MASIH" : "SELESAI"}
                      </TableCell>
                      <TableCell>{user.namaRegister}</TableCell>
                      <TableCell>{user.tanggalJualFormatted}</TableCell>
                      <TableCell>{user.nopol}</TableCell>
                      <TableCell>{user.almRegister}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={tableContainer}>
              <Pagination
                count={pages}
                page={page + 1}
                onChange={handleChange}
                color="primary"
                size={screenSize <= 600 ? "small" : "large"}
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TambahPenarikan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const colorRed = {
  color: "red",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};
