import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../../constants/helper";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  ButtonGroup,
  Autocomplete,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Col, Row, Form } from "react-bootstrap";

const CariLapPenjualanKreditTunai = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let date = new Date();
  const [cabangInput, setCabangInput] = useState("");
  let [inputDariTgl, setInputDariTgl] = useState(date);
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  const [allCabang, setAllCabang] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { screenSize } = useStateContext();

  const cabangOptions = allCabang.map((user) => ({
    label: `${user._id} - ${user.namaCabang}`,
  }));

  useEffect(() => {
    if (user.tipeUser === "OWN/DIREKSI") {
      getAllCabang();
    } else {
      setCabangInput(user.cabang._id);
    }
  }, []);

  const getAllCabang = async () => {
    setLoading(true);
    const allCabang = await axios.post(`${tempUrl}/cabangs`, {
      id: user._id,
      token: user.token,
    });
    setAllCabang(allCabang.data);
    setLoading(false);
  };

  const downloadPdfRinci = async () => {
    setLoading(true);
    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);
    const response = await axios.post(
      `${tempUrl}/jualsForLaporanPenjualanKreditTunai`,
      {
        dariTgl: inputDariTgl,
        sampaiTgl: inputSampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: cabangInput,
      }
    );

    let hal = 1;
    let y = 55;
    let total = 0;
    let totalValue = 0;
    let total0 = 0;
    let total6 = 0;
    let total9 = 0;
    let total12 = 0;
    let total15 = 0;
    let total18 = 0;
    let total21 = 0;
    let total24 = 0;
    let total30 = 0;
    let yCount = 0;
    let yTenor = 0;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF("l", "mm", [300, 210]);
    doc.setFontSize(12);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      200
    );
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`Hal: ${hal}`, 270, 5);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Penjualan Kredit & Tunai`, 110, 30);
    doc.setFontSize(10);
    doc.text(`Dari Tanggal : ${dariTgl} s/d ${sampaiTgl}`, 15, 40);
    doc.text(
      `Cabang : ${cabangInput === "" ? "SEMUA CABANG" : cabangInput}`,
      15,
      45
    );
    doc.line(15, y, 272.5, y);
    y += 5;
    doc.text(`No`, 18, y + 8);
    doc.text(`Tgl / No. Kredit / Customer`, 43, y + 8);
    doc.text(`Tenor (Bulan)`, 150, y);
    doc.text(`Surveyor`, 236, y + 8);
    y += 10;
    doc.line(15, y, 272.5, y);
    yTenor = y;
    yCount = y;
    for (let i = 0; i < response.data.length; i++) {
      y += 8;
      totalValue += response.data[i].hargaTunai;
      total += response.data[i].total;
      doc.text(`${response.data[i].no}`, 18, y - 2);
      doc.text(
        `${formatDate(response.data[i].tanggalJual)} - ${
          response.data[i].noRegister
        } - ${response.data[i].namaRegister}`,
        28,
        y - 2
      );
      doc.text(
        `${response.data[i].kodeSurveyor} - ${response.data[i].namaSurveyor}`,
        230,
        y - 2
      );
      doc.line(15, y, 272.5, y);

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "0") {
          total0 += parseInt(value);
          doc.text(`${value}`, 115.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "6") {
          total6 += parseInt(value);
          doc.text(`${value}`, 127.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "9") {
          total9 += parseInt(value);
          doc.text(`${value}`, 140.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "12") {
          total12 += parseInt(value);
          doc.text(`${value}`, 152.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "15") {
          total15 += parseInt(value);
          doc.text(`${value}`, 165.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "18") {
          total18 += parseInt(value);
          doc.text(`${value}`, 177.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "21") {
          total21 += parseInt(value);
          doc.text(`${value}`, 190.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "24") {
          total24 += parseInt(value);
          doc.text(`${value}`, 201.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "30") {
          total30 += parseInt(value);
          doc.text(`${value}`, 214.5, y - 2);
        }
      }

      if (y > 180) {
        doc.line(24, 55, 24, y - 8);
        doc.line(15, 55, 15, y);
        doc.line(110, 55, 110, y);
        doc.line(110, yTenor - 7, 272.5, yTenor - 7); // 1
        doc.text(`0`, 115.5, yTenor - 2);
        doc.line(122.5, yTenor - 7, 122.5, y); // 2
        doc.text(`6`, 127.5, yTenor - 2);
        doc.line(135, yTenor - 7, 135, y); // 3
        doc.text(`9`, 140.5, yTenor - 2);
        doc.line(147.5, yTenor - 7, 147.5, y); // 4
        doc.text(`12`, 152.5, yTenor - 2);
        doc.line(160, yTenor - 7, 160, y); // 5
        doc.text(`15`, 165.5, yTenor - 2);
        doc.line(172.5, yTenor - 7, 172.5, y); // 6
        doc.text(`18`, 177.5, yTenor - 2);
        doc.line(185, yTenor - 7, 185, y); // 7
        doc.text(`21`, 190.5, yTenor - 2);
        doc.line(197.5, yTenor - 7, 197.5, y); // 8
        doc.text(`24`, 201.5, yTenor - 2);
        doc.line(210, yTenor - 7, 210, y); // 9
        doc.text(`30`, 214.5, yTenor - 2);
        doc.line(222.5, 55, 222.5, y); // 10
        doc.line(272.5, 55, 272.5, y);
        doc.line(15, y, 272.5, y);
        doc.addPage();
        doc.text(
          `${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`,
          15,
          10
        );
        doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
        doc.setFontSize(16);
        doc.text(`Laporan Penjualan Kredit & Tunai`, 155, 30);
        doc.setFontSize(10);
        doc.text(`Dari Tanggal : ${dariTgl} s/d ${sampaiTgl}`, 15, 40);
        doc.text(`Periode : `, 15, 45);
        y = 55;
        doc.line(15, y, 272.5, y);
        y += 5;
        doc.text(`No`, 18, y + 8);
        doc.text(`Tgl / No. Kredit / Customer`, 43, y + 8);
        doc.text(`Tenor (Bulan)`, 150, y);
        doc.text(`Surveyor`, 335, y + 8);
        y += 10;
        doc.line(15, y, 272.5, y);
        hal++;
        doc.text(`Hal: ${hal}`, 330, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          200
        );
      }
    }

    y += 8;
    doc.setFont(undefined, "bold");
    doc.text(`TOTAL : `, 18, y - 2);
    doc.setFont(undefined, "normal");
    // Vertical Line
    doc.line(24, 55, 24, y - 8);
    doc.line(15, 55, 15, y);
    doc.line(110, 55, 110, y);
    doc.line(110, yTenor - 7, 272.5, yTenor - 7); // 1
    doc.text(`0`, 115.5, yTenor - 2);
    doc.text(`${total0}`, 115.5, y - 2);
    doc.line(122.5, yTenor - 7, 122.5, y); // 2
    doc.text(`6`, 127.5, yTenor - 2);
    doc.text(`${total6}`, 127.5, y - 2);
    doc.line(135, yTenor - 7, 135, y); // 3
    doc.text(`9`, 140.5, yTenor - 2);
    doc.text(`${total9}`, 140.5, y - 2);
    doc.line(147.5, yTenor - 7, 147.5, y); // 4
    doc.text(`12`, 152.5, yTenor - 2);
    doc.text(`${total12}`, 152.5, y - 2);
    doc.line(160, yTenor - 7, 160, y); // 5
    doc.text(`15`, 165.5, yTenor - 2);
    doc.text(`${total15}`, 165.5, y - 2);
    doc.line(172.5, yTenor - 7, 172.5, y); // 6
    doc.text(`18`, 177.5, yTenor - 2);
    doc.text(`${total18}`, 177.5, y - 2);
    doc.line(185, yTenor - 7, 185, y); // 7
    doc.text(`21`, 190.5, yTenor - 2);
    doc.text(`${total21}`, 190.5, y - 2);
    doc.line(197.5, yTenor - 7, 197.5, y); // 8
    doc.text(`24`, 201.5, yTenor - 2);
    doc.text(`${total24}`, 201.5, y - 2);
    doc.line(210, yTenor - 7, 210, y); // 9
    doc.text(`30`, 214.5, yTenor - 2);
    doc.text(`${total30}`, 214.5, y - 2);
    doc.line(222.5, 55, 222.5, y); // 10
    doc.line(272.5, 55, 272.5, y);
    doc.line(15, y, 272.5, y);
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Penjualan Kredit & Tunai
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Cabang :
              </Form.Label>
              {user.tipeUser === "OWN/DIREKSI" ? (
                <Col sm="9">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={cabangOptions}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={error && cabangInput.length === 0 && true}
                        helperText={
                          error &&
                          cabangInput.length === 0 &&
                          "Cabang harus diisi!"
                        }
                        placeholder="SEMUA CABANG"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) => {
                      setCabangInput(value.split(" ", 1)[0]);
                    }}
                  />
                </Col>
              ) : (
                <Col sm="9">
                  <Form.Control value={cabangInput} disabled />
                </Col>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputDariTgl}
                  onChange={(e) => setInputDariTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputDariTgl === null && true}
                      helperText={
                        error &&
                        inputDariTgl === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            color="secondary"
            startIcon={<PrintIcon />}
            onClick={() => {
              if (inputSampaiTgl === null || inputDariTgl === null) {
                setError(true);
              } else downloadPdfRinci();
            }}
          >
            Pdf
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default CariLapPenjualanKreditTunai;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};
