import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableDaftarStok } from "../../../components/ShowTable";
import { SearchBar, Loader, ScrollToTop } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TampilDaftarStok = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isDaftarStoksExist, setIsDaftarStoksExist] = useState(false);
  const [noBeli, setNoBeli] = useState("");
  const [tanggalBeli, setTanggalBeli] = useState("");
  const [supplier, setSupplier] = useState("");
  const [merk, setMerk] = useState("");
  const [tipe, setTipe] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [nopol, setNopol] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [alamatStnk, setAlamatStnk] = useState("");
  const [tglStnk, setTglStnk] = useState("");
  const [jenisBeli, setJenisBeli] = useState("");
  const [hargaSatuan, setHargaSatuan] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [noJual, setNoJual] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [tahun, setTahun] = useState("");
  const [totalBiayaPerawatan, setTotalBiayaPerawatan] = useState("");
  const [daftarStoksLength, setDaftarStoksLength] = useState("");
  const [value, setValue] = useState("semua");
  const [searchTerm, setSearchTerm] = useState("");
  const [daftarStoksData, setDaftarStoksData] = useState([]);

  const [sampaiTgl, setSampaiTgl] = useState(new Date());
  const [toggleCetak, setToggleCetak] = useState(false);

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };

  const columns = [
    { title: "No", field: "no" },
    { title: "No Beli", field: "noBeli" },
    { title: "Tanggal", field: "tanggalBeliFormatted" },
    { title: "Nama Tipe", field: "namaTipe" },
    { title: "No Rangka", field: "noRangka" },
    { title: "No Mesin", field: "noMesin" },
    { title: "Nama Stnk", field: "namaStnk" },
    { title: "Nopol", field: "nopol" },
    { title: "Tahun", field: "tahun" },
    { title: "Warna", field: "namaWarna" },
    { title: "Harga Beli", field: "hargaBeliTable" },
    { title: "Biaya-biaya", field: "totalBiayaPerawatanTable" },
    { title: "Total", field: "hargaTable" },
  ];

  const columnsRekap = [
    { title: "No", field: "no" },
    { title: "Tipe", field: "_id" },
    { title: "Total", field: "total" },
    { title: "Harga", field: "hargaTable" },
  ];

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const handleChangeLaporan = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    id ? getDaftarStokById() : setIsDaftarStoksExist(false);
  }, [id]);

  useEffect(() => {
    getDaftarStoksData();
  }, [value, page, searchTerm]);

  useEffect(() => {
    getDaftarStoksLength();
  }, []);

  const getRekapStoks = async () => {
    setLoading(true);
    let response;
    switch (value) {
      case "terjual":
        response = await axios.post(
          `${tempUrl}/daftarStoksRekapTerjualTanggal`,
          {
            sampaiTgl,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      case "belum":
        response = await axios.post(
          `${tempUrl}/daftarStoksRekapBlmTerjualTanggal`,
          {
            sampaiTgl,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      default:
        response = await axios.post(`${tempUrl}/daftarStoksRekapTanggal`, {
          sampaiTgl,
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
    }
    setLoading(false);
    downloadRekap(groupBy(response.data, "merk"));
  };

  const getDaftarStoksData = async () => {
    setLoading(true);
    let response;
    switch (value) {
      case "terjual":
        response = await axios.post(
          `${tempUrl}/daftarStoksTerjualPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            sampaiTgl: user.periode.periodeAkhir,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      case "belum":
        response = await axios.post(
          `${tempUrl}/daftarStoksBelumTerjualPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            sampaiTgl: user.periode.periodeAkhir,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      default:
        response = await axios.post(
          `${tempUrl}/daftarStoksPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            sampaiTgl: user.periode.periodeAkhir,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
    }
    setQuery(searchTerm);
    setDaftarStoksData(response.data.daftarStoks);
    setPage(response.data.page);
    setPages(response.data.totalPage);
    setRows(response.data.totalRows);
    setLoading(false);
  };

  const getDaftarStoksRinciData = async () => {
    setLoading(true);
    let response;
    switch (value) {
      case "terjual":
        response = await axios.post(
          `${tempUrl}/daftarStoksTerjualForRinciDocTanggal`,
          {
            sampaiTgl,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      case "belum":
        response = await axios.post(
          `${tempUrl}/daftarStoksBelumTerjualForRinciDocTanggal`,
          {
            sampaiTgl,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      default:
        response = await axios.post(
          `${tempUrl}/daftarStoksForRinciDocTanggal`,
          {
            sampaiTgl,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
    }
    setLoading(false);
    downloadPdf(groupBy(response.data, "merk"));
  };

  const getDaftarStoksLength = async () => {
    setLoading(true);
    const daftarStoksLength = await axios.post(`${tempUrl}/daftarStoksLength`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setDaftarStoksLength(daftarStoksLength.data);
    setLoading(false);
  };

  const getDaftarStoksForDoc = async () => {
    setLoading(true);
    let response;
    switch (value) {
      case "terjual":
        response = await axios.post(`${tempUrl}/daftarStoksTerjualForDoc`, {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        break;
      case "belum":
        response = await axios.post(
          `${tempUrl}/daftarStoksBelumTerjualForDoc`,
          {
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        break;
      default:
        response = await axios.post(`${tempUrl}/daftarStoksForDoc`, {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
    }
    setLoading(false);
    downloadExcel(response.data);
  };

  const getDaftarStokById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/daftarStoks/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsDaftarStoksExist(true);
      setNoBeli(response.data.noBeli);
      setTanggalBeli(formatDate(response.data.tanggalBeli));
      setSupplier(response.data.supplier);
      setMerk(response.data.merk);
      setTipe(response.data.tipe);
      setNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNopol(response.data.nopol);
      setNamaWarna(response.data.namaWarna);
      setTahun(response.data.tahun);
      setNamaStnk(response.data.namaStnk);
      setAlamatStnk(response.data.alamatStnk);
      setTglStnk(formatDate(response.data.tglStnk));
      setJenisBeli(response.data.jenisBeli);
      setHargaSatuan(response.data.hargaSatuan);
      setTanggalJual(response.data.tanggalJual);
      setNoJual(response.data.noJual);
      setTotalBiayaPerawatan(response.data.totalBiayaPerawatan);
    }
    setLoading(false);
  };

  const downloadPdf = (data) => {
    let tempTotal = 0;
    let tempTotalHargaSatuan = 0;
    let tempHargaSatuan = 0;
    let tempTotalBiayaPerawatan = 0;
    let tempBiayaPerawatan = 0;
    let tempSubTotal = 0;
    let tempLength = 0;
    let tempSubGroupHeight = 35;
    let tempHeight = 35;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatDate(date);
    const doc = new jsPDF("l", "mm", [300, 210]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Stok`, 140, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      200
    );
    function alignCol(data) {
      var col = data.column.index;
      if (col == 12) {
        data.cell.styles.halign = "right";
      }
    }
    let no = 0;
    for (var i = 0; i < Object.keys(data).length; i++) {
      doc.setFontSize(10);
      doc.text(
        `Merk : ${Object.values(data)[i][0].merk}`,
        15,
        tempSubGroupHeight
      );
      doc.autoTable({
        styles: {
          fontSize: 8,
        },
        startY: tempSubGroupHeight + 5,
        columns: columns.map((col) => ({
          ...col,
          dataKey: col.field,
        })),
        body: Object.values(data)[i].map((col) => {
          no++;
          return { ...col, no };
        }),
        headStyles: {
          fillColor: [117, 117, 117],
          color: [0, 0, 0],
        },
        didDrawPage: (d) => {
          tempSubGroupHeight = d.cursor.y + 15;
          tempHeight = d.cursor.y + 10;
        },
        didParseCell: function (cell, data) {
          alignCol(cell, data);
        },
      });
      Object.values(data)[i].map((val) => {
        tempSubTotal += val.hargaSatuan;
        tempTotal += val.hargaSatuan;
        tempTotalHargaSatuan += val.hargaSatuan - val.totalBiayaPerawatan;
        tempHargaSatuan += val.hargaSatuan - val.totalBiayaPerawatan;
        tempTotalBiayaPerawatan += val.totalBiayaPerawatan;
        tempBiayaPerawatan += val.totalBiayaPerawatan;
      });
      tempLength += Object.values(data)[i].length;
      if (tempHeight > 192) {
        doc.addPage();
        tempHeight = 10;
      }
      doc.setFontSize(8);
      doc.text(
        `Sub Total : ${
          Object.values(data)[i].length
        } | Rp ${tempSubTotal.toLocaleString("en-US")}`,
        15,
        tempHeight
      );
      doc.text(`${tempHargaSatuan.toLocaleString("en-US")}`, 243, tempHeight, {
        align: "right",
      });
      doc.text(
        `${tempBiayaPerawatan.toLocaleString("en-US")}`,
        260,
        tempHeight,
        {
          align: "right",
        }
      );
      doc.text(`${tempSubTotal.toLocaleString("en-US")}`, 285, tempHeight, {
        align: "right",
      });
      tempHargaSatuan = 0;
      tempBiayaPerawatan = 0;
      tempSubTotal = 0;
      no = 0;
    }
    doc.line(15, tempHeight + 4, 285, tempHeight + 4);
    doc.text(`Total : ${tempLength}`, 15, tempHeight + 8);
    doc.text(
      `Harga Beli : ${tempTotalHargaSatuan.toLocaleString("en-US")}`,
      285,
      tempHeight + 8,
      {
        align: "right",
      }
    );
    doc.text(
      `Biaya Perawatan : ${tempTotalBiayaPerawatan.toLocaleString("en-US")}`,
      285,
      tempHeight + 12,
      {
        align: "right",
      }
    );
    doc.text(
      `Total : ${tempTotal.toLocaleString("en-US")}`,
      285,
      tempHeight + 16,
      {
        align: "right",
      }
    );
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadRekap = (data) => {
    let tempTotal = 0;
    let tempSubTotal = 0;
    let tempSubGroupHeight = 35;
    let tempHeight = 35;
    let tempCount = 0;
    let tempCountAll = 0;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Rekap Stok`, 90, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    function alignCol(data) {
      var col = data.column.index;
      if (col == 3) {
        data.cell.styles.halign = "right";
      }
    }
    let no = 0;
    for (var i = 0; i < Object.keys(data).length; i++) {
      doc.setFontSize(10);
      doc.text(
        `Merk : ${Object.values(data)[i][0].merk}`,
        15,
        tempSubGroupHeight
      );
      doc.autoTable({
        styles: {
          fontSize: 8,
        },
        startY: tempSubGroupHeight + 5,
        columns: columnsRekap.map((col) => ({
          ...col,
          no,
          dataKey: col.field,
        })),
        body: Object.values(data)[i].map((col) => {
          no++;
          return { ...col, no };
        }),
        headStyles: {
          fillColor: [117, 117, 117],
          color: [0, 0, 0],
        },
        didDrawPage: (d) => {
          tempSubGroupHeight = d.cursor.y + 15;
          tempHeight = d.cursor.y + 10;
        },
        didParseCell: function (cell, data) {
          alignCol(cell, data);
        },
      });
      Object.values(data)[i].map((val) => {
        tempSubTotal += val.harga;
        tempTotal += val.harga;
      });
      for (let j = 0; j < Object.values(data)[i].length; j++) {
        tempCount += Object.values(data)[i][j].total;
      }
      if (tempHeight > 275) {
        doc.addPage();
        tempHeight = 10;
      }
      doc.setFontSize(8);
      doc.text(
        `Sub Total : ${tempCount} | Rp ${tempSubTotal.toLocaleString("en-US")}`,
        194,
        tempHeight,
        {
          align: "right",
        }
      );
      tempCountAll += tempCount;
      tempSubTotal = 0;
      tempCount = 0;
      no = 0;
    }
    doc.setFontSize(10);
    doc.text(
      `Total : ${tempCountAll} | Rp ${tempTotal.toLocaleString("en-US")}`,
      194,
      tempHeight + 8,
      {
        align: "right",
      }
    );
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadExcel = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Daftar Stok`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarStok.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Stok</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Daftar Stok
      </Typography>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            variant="contained"
            onClick={() => setToggleCetak(!toggleCetak)}
          >
            Cetak
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => getDaftarStoksForDoc()}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      {toggleCetak && (
        <>
          <Box sx={spacingTop}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Per Tanggal :
                  </Form.Label>
                  <Col sm="5">
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={sampaiTgl}
                      onChange={(e) => setSampaiTgl(e)}
                      customInput={
                        <TextField sx={{ width: "100%" }} size="small" />
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="contained"
                  startIcon={<FormatListBulletedIcon />}
                  onClick={() => getRekapStoks()}
                >
                  REKAP
                </Button>
                <Button
                  variant="contained"
                  startIcon={<PrintIcon />}
                  onClick={() => getDaftarStoksRinciData()}
                  sx={{ ml: 2 }}
                >
                  RINCI
                </Button>
              </Col>
            </Row>
          </Box>
          <hr />
        </>
      )}
      <Divider sx={dividerStyle} />
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Laporan</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="semua"
          value={value}
          onChange={handleChangeLaporan}
        >
          <FormControlLabel
            value="terjual"
            control={<Radio />}
            label="Terjual"
          />
          <FormControlLabel
            value="belum"
            control={<Radio />}
            label="Belum Terjual"
          />
          <FormControlLabel value="semua" control={<Radio />} label="Semua" />
        </RadioGroup>
      </FormControl>
      <Divider sx={{ marginTop: 1 }} />
      {isDaftarStoksExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No. Beli :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={noBeli} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tanggal Beli :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tanggalBeli} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Supplier :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={`${supplier ? supplier.kodeSupplier : ""} - ${
                          supplier ? supplier.namaSupplier : ""
                        }
                      `}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Merk :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={merk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tipe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nama Warna :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tahun :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tahun} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRightSmall}>
                      Total Biaya Perawatan :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={totalBiayaPerawatan.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No. Rangka :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={noRangka} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No. Mesin :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={noMesin} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nama STNK :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={namaStnk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Alamat STNK :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={alamatStnk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tanggal STNK :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tglStnk} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Jenis Beli :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={jenisBeli} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Harga Satuan :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={hargaSatuan.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableDaftarStok currentPosts={daftarStoksData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilDaftarStok;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
