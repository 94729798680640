import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader, SearchBar } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Autocomplete,
  Snackbar,
  Alert,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Col, Row, Form } from "react-bootstrap";
import SaveIcon from "@mui/icons-material/Save";

let getNowDate = () => {
  let date = new Date();
  return (
    date.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) +
    "-" +
    (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) +
    "-" +
    date.getFullYear()
  );
};

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

const TambahBeli = () => {
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [openTgl, setOpenTgl] = useState(false);
  const [kodeBeli, setKodeBeli] = useState("");
  const [kodeSupplier, setKodeSupplier] = useState("");
  const [jumlahBeli, setJumlahBeli] = useState(0);
  const [ppnBeli, setPpnBeli] = useState(0);
  const [potongan, setPotongan] = useState(0);
  const [lama, setLama] = useState(0);
  const [jenisBeli, setJenisBeli] = useState("");
  const [jatuhTempo, setJatuhTempo] = useState("");
  const [error, setError] = useState(false);
  const [errorTgl, setErrorTgl] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let nowDate = getNowDate();
  const [tanggalBeli, setTanggalBeli] = useState(`${nowDate}`);
  const [kodeCOA, setKodeCOA] = useState(user.coaKasir);
  const [openCOA, setOpenCOA] = useState(false);
  const [searchTermCOA, setSearchTermCOA] = useState("");
  const [COAsData, setCOAsData] = useState([]);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();

  const supplierOptions = suppliers.map((supplier) => ({
    label: `${supplier.kodeSupplier} - ${supplier.namaSupplier}`,
  }));

  const jenisBeliOption = [{ label: "BARU" }, { label: "BEKAS" }];

  const classes = useStyles();

  const handleClickOpenCOA = () => {
    setOpenCOA(true);
  };

  const handleCloseCOA = () => {
    setOpenCOA(false);
  };

  const tempPostsCOA = COAsData.filter((val) => {
    if (searchTermCOA === "") {
      return val;
    } else if (
      val.kodeCOA.toUpperCase().includes(searchTermCOA.toUpperCase()) ||
      val.namaCOA.toUpperCase().includes(searchTermCOA.toUpperCase())
    ) {
      return val;
    }
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseTgl = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenTgl(false);
  };

  useEffect(() => {
    getCOAsData();
    getSuppliersData();
    getNextKodeBeli();
  }, []);

  const getCOAsData = async () => {
    setLoading(true);
    const allCOAs = await axios.post(`${tempUrl}/COAsKasBank`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setCOAsData(allCOAs.data);
    setLoading(false);
  };

  const getNextKodeBeli = async () => {
    setLoading(true);
    const nextKodeBeli = await axios.post(`${tempUrl}/belisNextKode`, {
      periodeAwal: user.periode.periodeAwal,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKodeBeli(nextKodeBeli.data);
    setLoading(false);
  };

  const getSuppliersData = async () => {
    setLoading(true);
    const allSuppliers = await axios.post(`${tempUrl}/suppliers`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSuppliers(allSuppliers.data);
    setLoading(false);
  };

  const saveBeli = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    var dateParts = tanggalBeli.split("-");
    let tempTglBeli = new Date(
      `${+dateParts[2]}-${dateParts[1].toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}-${(1, +dateParts[0]).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`
    );

    var datePartsJtTempo = jatuhTempo.split("-");
    let tempTglJatuhTempo = new Date(
      `${+datePartsJtTempo[2]}-${(datePartsJtTempo[1] - 1).toLocaleString(
        "en-US",
        {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }
      )}-${(+datePartsJtTempo[0]).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`
    );

    let tempDariTglInput = new Date(user.periode.periodeAwal);
    let tempSampaiTglInput = new Date(user.periode.periodeAkhir);

    let tglAntara =
      tempTglBeli >= tempDariTglInput && tempTglBeli <= tempSampaiTglInput;

    let isFailedValidation =
      tanggalBeli.length === 0 ||
      kodeSupplier.length === 0 ||
      jenisBeli.length === 0 ||
      kodeCOA.length === 0;

    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else if (tglAntara) {
      try {
        setLoading(true);
        let tempSupplier = await axios.post(`${tempUrl}/supplierByKode`, {
          kodeSupplier,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        let tempCOA = await axios.post(`${tempUrl}/COAByKode`, {
          kodeCOA,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        await axios.post(`${tempUrl}/saveBeli`, {
          dariTgl: user.periode.periodeAwal,
          sampaiTgl: user.periode.periodeAkhir,
          periode: user.periode.periodeAwal,
          kodeSupplier: tempSupplier.data._id,
          tanggalBeli: `${tempTglBeli}`,
          jumlahBeli,
          ppnBeli,
          potongan,
          lama,
          jenisBeli,
          COA: tempCOA.data._id,
          jatuhTempo: `${tempTglBeli}`,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate("/daftarBeli");
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    } else {
      setErrorTgl(true);
      setOpenTgl(!openTgl);
    }
    setValidated(true);
  };

  const countDateDuration = (e, value) => {
    var splitedDay = tanggalBeli.toString().split("-", 1)[0];
    var splitedMonth = tanggalBeli.toString().split("-")[1];
    var splitedYear = tanggalBeli.toString().split("-")[2];
    var combineDate = `${splitedYear}-${splitedMonth}-${splitedDay}`;
    var tempDate = new Date(combineDate);
    var numberOfDaysToAdd =
      e.target.value !== "" ? parseInt(e.target.value) : 0;
    var result = tempDate.setDate(tempDate.getDate() + numberOfDaysToAdd);
    var finalDate = new Date(result);
    var calculatedDateResult =
      finalDate.getDate().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      (finalDate.getMonth() + 1).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      "-" +
      finalDate.getFullYear();
    setJatuhTempo(calculatedDateResult);
    setLama(e.target.value.toUpperCase());
  };

  const jatuhTempoFunction = (e) => {
    var splitedDayTanggalBeli = tanggalBeli.toString().split("-", 1)[0];
    var splitedMonthTanggalBeli = tanggalBeli.toString().split("-")[1];
    var splitedYearTanggalBeli = tanggalBeli.toString().split("-")[2];
    var combineDateTanggalBeli = `${splitedYearTanggalBeli}-${splitedMonthTanggalBeli}-${splitedDayTanggalBeli}`;
    var tempDateTanggalBeli = new Date(combineDateTanggalBeli);
    var splitedDayInputDate = e.target.value.toString().split("-", 1)[0];
    var splitedMonthInputDate = e.target.value.toString().split("-")[1];
    var splitedYearInputDate = e.target.value.toString().split("-")[2];
    var combineDateInput = `${splitedYearInputDate}-${splitedMonthInputDate}-${splitedDayInputDate}`;
    var tempDateInput = new Date(combineDateInput);

    const diffTime = Math.abs(tempDateInput - tempDateTanggalBeli);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    setLama(diffDays);
    setJatuhTempo(e.target.value.toUpperCase());
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Box>
      <Typography color="#757575">Pembelian</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Beli
      </Typography>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveBeli}>
          <Box sx={textFieldContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tanggal <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={tanggalBeli}
                        placeholder="tanggal-bulan-tahun"
                        required
                        onChange={(e) =>
                          setTanggalBeli(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Tanggal harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Kode Supplier <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={supplierOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeSupplier.length === 0 && true}
                            helperText={
                              error &&
                              kodeSupplier.length === 0 &&
                              "Kode Supplier harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) =>
                          setKodeSupplier(value.split(" ", 1)[0])
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Jenis Motor <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={jenisBeliOption}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && jenisBeli.length === 0 && true}
                            helperText={
                              error &&
                              jenisBeli.length === 0 &&
                              "Jenis Motor harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setJenisBeli(value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Jumlah :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={jumlahBeli} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Potongan :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={potongan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Lama (Hari) <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={lama}
                        // onChange={(e, value) => countDateDuration(e, value)}
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Lama harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Jatuh Tempo :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={tanggalBeli}
                        disabled
                        // onChange={(e) => jatuhTempoFunction(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      COA <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={kodeCOA}
                        required
                        placeholder="Pilih..."
                        onClick={() => handleClickOpenCOA()}
                      />
                      <Form.Control.Feedback type="invalid">
                        COA harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={textFieldStyle}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/daftarBeli")}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={dividerStyle} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      {errorTgl && (
        <Snackbar
          open={openTgl}
          autoHideDuration={6000}
          onClose={handleCloseTgl}
        >
          <Alert onClose={handleCloseTgl} severity="error" sx={alertBox}>
            Periode yang aktif {user.periode.namaPeriode}
          </Alert>
        </Snackbar>
      )}
      <Dialog
        open={openCOA}
        onClose={handleCloseCOA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Pilih Data COA`}</DialogTitle>
        <DialogActions>
          <Box sx={dialogContainer}>
            <SearchBar setSearchTerm={setSearchTermCOA} />
            <TableContainer component={Paper} sx={dialogWrapper}>
              <Table aria-label="simple table">
                <TableHead className={classes.root}>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      className={classes.tableRightBorder}
                    >
                      Kode COA
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempPostsCOA
                    .filter((val) => {
                      if (searchTermCOA === "") {
                        return val;
                      } else if (
                        val.kodeCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase()) ||
                        val.namaCOA
                          .toUpperCase()
                          .includes(searchTermCOA.toUpperCase())
                      ) {
                        return val;
                      }
                    })
                    .map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { bgcolor: Colors.grey300 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setKodeCOA(user.kodeCOA);
                          handleCloseCOA();
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {user.kodeCOA}
                        </TableCell>
                        <TableCell>{user.namaCOA}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TambahBeli;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const textFieldContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const textFieldStyle = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const colorRed = {
  color: "red",
};


const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};