import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  Divider,
  Alert,
  Button,
  TextField,
  Snackbar,
  Paper,
  Autocomplete,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Col, Row, Form } from "react-bootstrap";

const TambahUser = () => {
  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [kodeCabang, setKodeCabang] = useState("");
  const [namaPeriode, setNamaPeriode] = useState("");
  const [username, setUsername] = useState("");
  const [tipeUser, setTipeUser] = useState("");
  const [kodeKolektor, setKodeKolektor] = useState("");
  const [kodeKwitansi, setKodeKwitansi] = useState("");
  const [coaKasir, setCoaKasir] = useState("");
  const [password, setPassword] = useState("");
  const [checkAllAkses, setCheckAllAkses] = useState(false);

  // Akses Master
  const [motor, setMotor] = useState(false);
  const [area, setArea] = useState(false);
  const [bukuBesar, setBukuBesar] = useState(false);
  const [dealer, setDealer] = useState(false);
  const [kolektor, setKolektor] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [pekerjaan, setPekerjaan] = useState(false);
  const [surveyor, setSurveyor] = useState(false);
  const [leasing, setLeasing] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [cabang, setCabang] = useState(false);

  // Akses Pembelian
  const [beli, setBeli] = useState(false);

  // Akses Penjualan
  const [register, setRegister] = useState(false);
  const [reject, setReject] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [jual, setJual] = useState(false);

  // Akses Laporan
  const [daftarStok, setDaftarStok] = useState(false);
  const [totalPiutang, setTotalPiutang] = useState(false);
  const [tunggakan, setTunggakan] = useState(false);
  const [spkHarusDibuat, setSpkHarusDibuat] = useState(false);
  const [penerimaanKas, setPenerimaanKas] = useState(false);
  const [penerimaanKasBedaCabang, setPenerimaanKasBedaCabang] = useState(false);
  const [kasHarian, setKasHarian] = useState(false);
  const [rekapPenerimaan, setRekapPenerimaan] = useState(false);
  const [penambahanPiutang, setPenambahanPiutang] = useState(false);
  const [lapPenjualan, setLapPenjualan] = useState(false);
  const [labaRugiPerUnit, setLabaRugiPerUnit] = useState(false);
  const [biayaTarikPerSurveyor, setBiayaTarikPerSurveyor] = useState(false);
  const [jemputanPerKolektor, setJemputanPerKolektor] = useState(false);
  const [konsumenAktifPerSurveyor, setKonsumenAktifPerSurveyor] =
    useState(false);
  const [point3Bulan, setPoint3Bulan] = useState(false);

  // Akses Piutang
  const [angsuran, setAngsuran] = useState(false);
  const [percepatan, setPercepatan] = useState(false);
  const [angsuranBedaCabang, setAngsuranBedaCabang] = useState(false);
  const [percepatanBedaCabang, setPercepatanBedaCabang] = useState(false);
  const [sp, setSp] = useState(false);
  const [st, setSt] = useState(false);
  const [penarikan, setPenarikan] = useState(false);
  const [titip, setTitip] = useState(false);
  const [sweeping, setSweeping] = useState(false);
  const [motorHilang, setMotorHilang] = useState(false);
  const [konsumenJatuhTempo, setKonsumenJatuhTempo] = useState(false);
  const [pointKembali, setPointKembali] = useState(false);

  // Akses Perawatan
  const [biayaPerawatan, setBiayaPerawatan] = useState(false);

  // Akses Finance
  const [kasMasuk, setKasMasuk] = useState(false);
  const [kasKeluar, setKasKeluar] = useState(false);
  const [bankMasuk, setBankMasuk] = useState(false);
  const [bankKeluar, setBankKeluar] = useState(false);

  // Akses Accounting
  const [posting, setPosting] = useState(false);
  const [unposting, setUnposting] = useState(false);
  const [aktivitasBukuBesar, setAktivitasBukuBesar] = useState(false);
  const [labaRugi, setLabaRugi] = useState(false);
  const [neraca, setNeraca] = useState(false);
  const [neracaSaldo, setNeracaSaldo] = useState(false);
  const [tutupPeriode, setTutupPeriode] = useState(false);
  const [gantiPeriode, setGantiPeriode] = useState(false);
  const [jurnalUmum, setJurnalUmum] = useState(false);

  // Akses Utility
  const [profilUser, setProfilUser] = useState(false);
  const [daftarUser, setDaftarUser] = useState(false);
  const [settingAkses, setSettingAkses] = useState(false);

  const [cabangs, setCabangs] = useState([]);
  const [coaSubTunais, setCoaSubTunais] = useState([]);
  const [periodesData, setPeriodesData] = useState([]);
  const [kolektors, setKolektors] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openAlertUsername, setOpenAlertUsername] = useState(false);
  const [openAlertKodeKwitansiCabang, setOpenAlertKodeKwitansiCabang] =
    useState(false);

  const handleClickOpenAlertUsername = () => {
    setOpenAlertUsername(true);
  };

  const handleCloseAlertUsername = () => {
    setOpenAlertUsername(false);
  };

  const handleClickOpenAlertKodeKwitansiCabang = () => {
    setOpenAlertKodeKwitansiCabang(true);
  };

  const handleCloseAlertKodeKwitansiCabang = () => {
    setOpenAlertKodeKwitansiCabang(false);
  };

  const kolektorOptions = kolektors.map((kolektor) => ({
    label: `${kolektor.kodeKolektor} - ${kolektor.namaKolektor}`,
  }));

  const tipeUserOption = [
    { label: "MGR" },
    { label: "FIN" },
    { label: "EKS" },
    { label: "ADM" },
  ];
  const tipeUserOptionFinance = [
    { label: "FIN" },
    { label: "EKS" },
    { label: "ADM" },
  ];

  const tipeUserOptionOwner = [
    { label: "OWN/DIREKSI" },
    { label: "MGR" },
    { label: "FIN" },
    { label: "EKS" },
    { label: "ADM" },
  ];

  const periodeOptions = periodesData.map((periode) => ({
    label: `${periode.namaPeriode}`,
  }));

  const cabangOptions = cabangs.map((cabang) => ({
    label: `${cabang._id} - ${cabang.namaCabang}`,
  }));

  const coaSubTunaiOptions = coaSubTunais.map((coaSubTunai) => ({
    label: `${coaSubTunai.kodeCOA} - ${coaSubTunai.namaCOA}`,
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getKolektor();
    getPeriodesData();
    if (user.tipeUser === "OWN/DIREKSI") {
      getCabangsData();
    } else {
      setKodeCabang(user.cabang._id);
    }

    if (user.tipeUser === "ADM") {
      setTipeUser("ADM");
    }
    getCoaSubTunai();
  }, []);

  const getKolektor = async () => {
    setLoading(true);
    const allKolektors = await axios.post(`${tempUrl}/kolektors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setKolektors(allKolektors.data);
    setLoading(false);
  };

  const getPeriodesData = async () => {
    setLoading(true);
    try {
      const allPeriode = await axios.post(`${tempUrl}/tutupPeriodesDesc`, {
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setPeriodesData(allPeriode.data);
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  };

  const getCabangsData = async (kodeUnit) => {
    setKodeCabang("");
    const response = await axios.post(`${tempUrl}/cabangs`, {
      id: user._id,
      token: user.token,
    });
    setCabangs(response.data);
  };

  const getCoaSubTunai = async (kodeUnit) => {
    setCoaKasir("");
    const response = await axios.post(`${tempUrl}/COAsSubKasTunai`, {
      id: user._id,
      token: user.token,
    });
    setCoaSubTunais(response.data);
  };

  const saveUser = async (e) => {
    e.preventDefault();

    let isFailedValidation =
      username.length === 0 ||
      password.length === 0 ||
      tipeUser.length === 0 ||
      namaPeriode.length === 0 ||
      coaKasir.length === 0 ||
      kodeCabang.length === 0;

    if (tipeUser === "EKS") {
      isFailedValidation =
        username.length === 0 ||
        password.length === 0 ||
        tipeUser.length === 0 ||
        kodeKolektor.length === 0 ||
        namaPeriode.length === 0 ||
        coaKasir.length === 0 ||
        kodeCabang.length === 0;
    }

    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        let tempUsername = await axios.post(`${tempUrl}/getUsername`, {
          username,
          id: user._id,
          token: user.token,
        });
        let tempKodeKwitansi = await axios.post(`${tempUrl}/getKodeKwitansi`, {
          kodeKwitansi,
          id: user._id,
          token: user.token,
          kodeCabang: kodeCabang.split(" ", 1)[0],
        });
        const pickedPeriode = await axios.post(
          `${tempUrl}/tutupPeriodeByNamaPeriode`,
          {
            namaPeriode,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        let tempPeriode = await axios.post(`${tempUrl}/tutupPeriodes`, {
          id: user._id,
          token: user.token,
          kodeCabang: kodeCabang.split(" ", 1)[0],
        });

        if (tempPeriode.data.length === 0) {
          // Create Periode and Neraca Saldo
          await axios.post(`${tempUrl}/saveTutupPeriode`, {
            periodeAwal: pickedPeriode.data.periodeAwal,
            periodeAkhir: pickedPeriode.data.periodeAkhir,
            namaPeriode: pickedPeriode.data.namaPeriode,
            id: user._id,
            token: user.token,
            kodeCabang: kodeCabang.split(" ", 1)[0],
          });
          function subtractMonths(date, months) {
            date.setMonth(date.getMonth() - months);
            return date;
          }

          const newBulan = new Date(pickedPeriode.data.periodeAwal);
          let tempBulan =
            newBulan.getDate() +
            "-" +
            (newBulan.getMonth() + 1) +
            "-" +
            newBulan.getFullYear();

          const minOneBulan = subtractMonths(newBulan, 1);
          let tempMinOneBulan =
            minOneBulan.getDate() +
            "-" +
            (minOneBulan.getMonth() + 1) +
            "-" +
            minOneBulan.getFullYear();

          // Save 2 months
          await axios.post(`${tempUrl}/saveNeracaSaldo`, {
            bulan: tempMinOneBulan,
            id: user._id,
            token: user.token,
            kodeCabang: kodeCabang.split(" ", 1)[0],
          });
          await axios.post(`${tempUrl}/saveNeracaSaldo`, {
            bulan: tempBulan,
            id: user._id,
            token: user.token,
            kodeCabang: kodeCabang.split(" ", 1)[0],
          });
        }

        let isUsernameAlreadyExist = tempUsername.data.length > 0;
        let isKodeKwitansiCabangAlreadyExist = tempKodeKwitansi.data.length > 0;
        if (isUsernameAlreadyExist) {
          handleClickOpenAlertUsername();
        } else if (isKodeKwitansiCabangAlreadyExist) {
          handleClickOpenAlertKodeKwitansiCabang();
        } else {
          setLoading(true);

          let tempKodeKolektor;
          if (tipeUser === "EKS") {
            let pickedKolektor = await axios.post(`${tempUrl}/kolektorByKode`, {
              kodeKolektor,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang._id,
            });

            if (pickedKolektor.data) {
              tempKodeKolektor = pickedKolektor.data._id;
            }
          }

          await axios.post(`${tempUrl}/auth/register`, {
            username,
            password,
            tipeUser,
            kodeKolektor: tempKodeKolektor,
            periode: pickedPeriode.data._id,
            kodeKwitansi,
            coaKasir: coaKasir.split(" ", 1)[0],
            akses: {
              motor,
              area,
              bukuBesar,
              dealer,
              kolektor,
              marketing,
              pekerjaan,
              surveyor,
              leasing,
              supplier,
              cabang,
              beli,
              register,
              reject,
              customer,
              jual,
              daftarStok,
              totalPiutang,
              tunggakan,
              spkHarusDibuat,
              penerimaanKas,
              penerimaanKasBedaCabang,
              kasHarian,
              rekapPenerimaan,
              penambahanPiutang,
              lapPenjualan,
              labaRugiPerUnit,
              biayaTarikPerSurveyor,
              jemputanPerKolektor,
              konsumenAktifPerSurveyor,
              point3Bulan,
              angsuran,
              percepatan,
              angsuranBedaCabang,
              percepatanBedaCabang,
              sp,
              st,
              penarikan,
              titip,
              sweeping,
              motorHilang,
              konsumenJatuhTempo,
              pointKembali,
              biayaPerawatan,
              kasMasuk,
              kasKeluar,
              bankMasuk,
              bankKeluar,
              posting,
              unposting,
              aktivitasBukuBesar,
              labaRugi,
              neraca,
              neracaSaldo,
              tutupPeriode,
              gantiPeriode,
              jurnalUmum,
              setting: settingAkses,
              profilUser,
              daftarUser,
            },
            kodeCabang: kodeCabang.split(" ", 1)[0],
            id: user._id,
            token: user.token,
          });
          setLoading(false);
          navigate("/daftarUser");
        }
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  let InputTipeUser = <Form.Control value={tipeUser} disabled />;

  if (user.tipeUser === "OWN/DIREKSI") {
    InputTipeUser = (
      <Autocomplete
        size="small"
        disablePortal
        id="combo-box-demo"
        options={tipeUserOptionOwner}
        renderInput={(params) => (
          <TextField
            size="small"
            error={error && tipeUser.length === 0 && true}
            helperText={
              error && tipeUser.length === 0 && "Tipe User harus diisi!"
            }
            {...params}
          />
        )}
        onInputChange={(e, value) => setTipeUser(value)}
      />
    );
  } else if (user.tipeUser === "FIN") {
    InputTipeUser = (
      <Autocomplete
        size="small"
        disablePortal
        id="combo-box-demo"
        options={tipeUserOptionFinance}
        renderInput={(params) => (
          <TextField
            size="small"
            error={error && tipeUser.length === 0 && true}
            helperText={
              error && tipeUser.length === 0 && "Tipe User harus diisi!"
            }
            {...params}
          />
        )}
        onInputChange={(e, value) => setTipeUser(value)}
      />
    );
  } else if (user.tipeUser === "MGR") {
    InputTipeUser = (
      <Autocomplete
        size="small"
        disablePortal
        id="combo-box-demo"
        options={tipeUserOption}
        renderInput={(params) => (
          <TextField
            size="small"
            error={error && tipeUser.length === 0 && true}
            helperText={
              error && tipeUser.length === 0 && "Tipe User harus diisi!"
            }
            {...params}
          />
        )}
        onInputChange={(e, value) => setTipeUser(value)}
      />
    );
  }

  return (
    <Box>
      <Typography color="#757575">User</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah User
      </Typography>
      <Dialog
        open={openAlertUsername}
        onClose={handleCloseAlertUsername}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Data Username Sama`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Username ${username} sudah ada, ganti Username!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertUsername}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAlertKodeKwitansiCabang}
        onClose={handleCloseAlertKodeKwitansiCabang}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Data Kode Kwitansi Sama`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Kode Kwitansi ${kodeKwitansi} di Cabang ${
              kodeCabang.split(" ", 1)[0]
            } sudah ada, ganti Kode Kwitansi!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertKodeKwitansiCabang}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Form noValidate validated={validated} onSubmit={saveUser}>
        <Divider sx={dividerStyle} />
        <Paper sx={contentContainer} elevation={12}>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Username <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={username}
                        required
                        onChange={(e) =>
                          setUsername(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Username harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tipe User <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">{InputTipeUser}</Col>
                  </Form.Group>
                </Col>
              </Row>
              {tipeUser === "EKS" && (
                <Row>
                  <Col>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="3" style={textRight}>
                        Kode Eksekutor <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="9">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={kolektorOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeKolektor.length === 0 && true}
                              helperText={
                                error &&
                                kodeKolektor.length === 0 &&
                                "Kode Eksekutor harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) =>
                            setKodeKolektor(value.split(" ", 1)[0])
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Periode <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={periodeOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && namaPeriode.length === 0 && true}
                            helperText={
                              error &&
                              namaPeriode.length === 0 &&
                              "Periode harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setNamaPeriode(value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Cabang <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      {user.tipeUser === "OWN/DIREKSI" ? (
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={cabangOptions}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              error={error && kodeCabang.length === 0 && true}
                              helperText={
                                error &&
                                kodeCabang.length === 0 &&
                                "Kode Cabang harus diisi!"
                              }
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => setKodeCabang(value)}
                          value={kodeCabang}
                        />
                      ) : (
                        <Form.Control value={kodeCabang} disabled />
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Kode Kwitansi <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={kodeKwitansi}
                        required
                        onChange={(e) =>
                          setKodeKwitansi(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Kode Kwitansi harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      COA Kasir <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={coaSubTunaiOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && coaKasir.length === 0 && true}
                            helperText={
                              error &&
                              coaKasir.length === 0 &&
                              "COA Kasir harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setCoaKasir(value)}
                        value={coaKasir}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Password <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={password}
                        type="password"
                        required
                        onChange={(e) =>
                          setPassword(e.target.value.toUpperCase())
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Password harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </Paper>
        {user.tipeUser !== "ADM" && (
          <Paper sx={contentContainer} elevation={12}>
            <Typography variant="h5" sx={[labelInput, spacingTop]}>
              Atur Hak Akses
            </Typography>
            <Box sx={showDataContainerCheck}>
              <Row>
                <Col>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={checkAllAkses} />}
                      label="Pilih Semua"
                      onChange={() => {
                        setCheckAllAkses(!checkAllAkses);
                        // Akses Master
                        setMotor(!checkAllAkses);
                        setArea(!checkAllAkses);
                        setBukuBesar(!checkAllAkses);
                        setDealer(!checkAllAkses);
                        setKolektor(!checkAllAkses);
                        setMarketing(!checkAllAkses);
                        setPekerjaan(!checkAllAkses);
                        setSurveyor(!checkAllAkses);
                        setLeasing(!checkAllAkses);
                        setSupplier(!checkAllAkses);
                        setCabang(!checkAllAkses);
                        // Akses Pembelian
                        setBeli(!checkAllAkses);
                        // Akses Penjualan
                        setRegister(!checkAllAkses);
                        setReject(!checkAllAkses);
                        setCustomer(!checkAllAkses);
                        setJual(!checkAllAkses);
                        // Akses Laporan
                        setDaftarStok(!checkAllAkses);
                        setTotalPiutang(!checkAllAkses);
                        setTunggakan(!checkAllAkses);
                        setSpkHarusDibuat(!checkAllAkses);
                        setPenerimaanKas(!checkAllAkses);
                        setPenerimaanKasBedaCabang(!checkAllAkses);
                        setKasHarian(!checkAllAkses);
                        setRekapPenerimaan(!checkAllAkses);
                        setPenambahanPiutang(!checkAllAkses);
                        setLapPenjualan(!checkAllAkses);
                        setLabaRugiPerUnit(!checkAllAkses);
                        setBiayaTarikPerSurveyor(!checkAllAkses);
                        setJemputanPerKolektor(!checkAllAkses);
                        setKonsumenAktifPerSurveyor(!checkAllAkses);
                        setPoint3Bulan(!checkAllAkses);
                        // Akses Piutang
                        setAngsuran(!checkAllAkses);
                        setPercepatan(!checkAllAkses);
                        setAngsuranBedaCabang(!checkAllAkses);
                        setPercepatanBedaCabang(!checkAllAkses);
                        setSp(!checkAllAkses);
                        setSt(!checkAllAkses);
                        setPenarikan(!checkAllAkses);
                        setTitip(!checkAllAkses);
                        setSweeping(!checkAllAkses);
                        setMotorHilang(!checkAllAkses);
                        setKonsumenJatuhTempo(!checkAllAkses);
                        setPointKembali(!checkAllAkses);
                        // Akses Perawatan
                        setBiayaPerawatan(!checkAllAkses);
                        // Akses Finance
                        setKasMasuk(!checkAllAkses);
                        setKasKeluar(!checkAllAkses);
                        setBankMasuk(!checkAllAkses);
                        setBankKeluar(!checkAllAkses);
                        // Akses Accounting
                        setPosting(!checkAllAkses);
                        setUnposting(!checkAllAkses);
                        setAktivitasBukuBesar(!checkAllAkses);
                        setLabaRugi(!checkAllAkses);
                        setNeraca(!checkAllAkses);
                        setNeracaSaldo(!checkAllAkses);
                        setTutupPeriode(!checkAllAkses);
                        setGantiPeriode(!checkAllAkses);
                        setJurnalUmum(!checkAllAkses);
                        // Akses Utility
                        setProfilUser(!checkAllAkses);
                        setDaftarUser(!checkAllAkses);
                        setSettingAkses(!checkAllAkses);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Box>
            <Box sx={showDataContainerCheck}>
              <Row>
                <Col>
                  <Typography variant="p" sx={[spacingTop]}>
                    Master
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={motor} />}
                      label="Motor"
                      onChange={() => setMotor(!motor)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={area} />}
                      label="Area"
                      onChange={() => setArea(!area)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bukuBesar} />}
                      label="Buku Besar"
                      onChange={() => setBukuBesar(!bukuBesar)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={dealer} />}
                      label="Dealer"
                      onChange={() => setDealer(!dealer)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kolektor} />}
                      label="Eksekutor"
                      onChange={() => setKolektor(!kolektor)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={marketing} />}
                      label="Marketing"
                      onChange={() => setMarketing(!marketing)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={pekerjaan} />}
                      label="Pekerjaan"
                      onChange={() => setPekerjaan(!pekerjaan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={surveyor} />}
                      label="Surveyor"
                      onChange={() => setSurveyor(!surveyor)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={leasing} />}
                      label="Leasing"
                      onChange={() => setLeasing(!leasing)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={supplier} />}
                      label="Supplier"
                      onChange={() => setSupplier(!supplier)}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={cabang} />}
                      label="Cabang"
                      onChange={() => setCabang(!cabang)}
                    />
                  </FormGroup> */}
                  <Typography variant="p" sx={[spacingTop]}>
                    Pembelian
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={beli} />}
                      label="Beli"
                      onChange={() => setBeli(!beli)}
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Penjualan
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={register} />}
                      label="Register"
                      onChange={() => setRegister(!register)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={reject} />}
                      label="Reject"
                      onChange={() => setReject(!reject)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={customer} />}
                      label="Customer"
                      onChange={() => setCustomer(!customer)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jual} />}
                      label="Jual"
                      onChange={() => setJual(!jual)}
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Laporan
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={daftarStok} />}
                      label="Daftar Stok"
                      onChange={() => setDaftarStok(!daftarStok)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={totalPiutang} />}
                      label="Total Piutang"
                      onChange={() => setTotalPiutang(!totalPiutang)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tunggakan} />}
                      label="Tunggakan"
                      onChange={() => setTunggakan(!tunggakan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={spkHarusDibuat} />}
                      label="SPK harus dibuat"
                      onChange={() => setSpkHarusDibuat(!spkHarusDibuat)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={penerimaanKas} />}
                      label="Penerimaan Kas"
                      onChange={() => setPenerimaanKas(!penerimaanKas)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={penerimaanKasBedaCabang} />}
                      label="Penerimaan Kas Beda Cabang"
                      onChange={() =>
                        setPenerimaanKasBedaCabang(!penerimaanKasBedaCabang)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kasHarian} />}
                      label="Kas Harian"
                      onChange={() => setKasHarian(!kasHarian)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={rekapPenerimaan} />}
                      label="Rekap Penerimaan"
                      onChange={() => setRekapPenerimaan(!rekapPenerimaan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={penambahanPiutang} />}
                      label="Penambahan Piutang"
                      onChange={() => setPenambahanPiutang(!penambahanPiutang)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={lapPenjualan} />}
                      label="Lap. Penjualan"
                      onChange={() => setLapPenjualan(!lapPenjualan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={labaRugiPerUnit} />}
                      label="L/R Per Unit"
                      onChange={() => setLabaRugiPerUnit(!labaRugiPerUnit)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={biayaTarikPerSurveyor} />}
                      label="Biaya Tarik/Surveyor"
                      onChange={() =>
                        setBiayaTarikPerSurveyor(!biayaTarikPerSurveyor)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jemputanPerKolektor} />}
                      label="Jemputan/Kolektor"
                      onChange={() =>
                        setJemputanPerKolektor(!jemputanPerKolektor)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={konsumenAktifPerSurveyor} />}
                      label="Konsumen Aktif/Surveyor"
                      onChange={() =>
                        setKonsumenAktifPerSurveyor(!konsumenAktifPerSurveyor)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={point3Bulan} />}
                      label="Point 3 Bulan"
                      onChange={() => setPoint3Bulan(!point3Bulan)}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Typography variant="p" sx={[spacingTop]}>
                    Piutang
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={angsuran} />}
                      label="Angsuran"
                      onChange={() => setAngsuran(!angsuran)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={percepatan} />}
                      label="Percepatan"
                      onChange={() => setPercepatan(!percepatan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={angsuranBedaCabang} />}
                      label="Angsuran Beda Cabang"
                      onChange={() =>
                        setAngsuranBedaCabang(!angsuranBedaCabang)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={percepatanBedaCabang} />}
                      label="Percepatan Beda Cabang"
                      onChange={() =>
                        setPercepatanBedaCabang(!percepatanBedaCabang)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sp} />}
                      label="SP"
                      onChange={() => setSp(!sp)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={st} />}
                      label="ST"
                      onChange={() => setSt(!st)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={penarikan} />}
                      label="Pelunasan Program"
                      onChange={() => setPenarikan(!penarikan)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={titip} />}
                      label="Tarik/Titip"
                      onChange={() => setTitip(!titip)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={sweeping} />}
                      label="Sweeping"
                      onChange={() => setSweeping(!sweeping)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={motorHilang} />}
                      label="Motor Hilang"
                      onChange={() => setMotorHilang(!motorHilang)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={konsumenJatuhTempo} />}
                      label="Konsumen Jatuh Tempo"
                      onChange={() =>
                        setKonsumenJatuhTempo(!konsumenJatuhTempo)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={pointKembali} />}
                      label="Point Kembali"
                      onChange={() => setPointKembali(!pointKembali)}
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Perawatan
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={biayaPerawatan} />}
                      label="Biaya Perawatan"
                      onChange={() => setBiayaPerawatan(!biayaPerawatan)}
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Finance
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kasMasuk} />}
                      label="Kas Masuk"
                      onChange={() => setKasMasuk(!kasMasuk)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={kasKeluar} />}
                      label="Kas Keluar"
                      onChange={() => setKasKeluar(!kasKeluar)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bankMasuk} />}
                      label="Bank Masuk"
                      onChange={() => setBankMasuk(!bankMasuk)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={bankKeluar} />}
                      label="Bank Keluar"
                      onChange={() => setBankKeluar(!bankKeluar)}
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Accounting
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={posting} />}
                      label="Posting"
                      onChange={() => setPosting(!posting)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={unposting} />}
                      label="Unposting"
                      onChange={() => setUnposting(!unposting)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={aktivitasBukuBesar} />}
                      label="Aktivitas Buku Besar"
                      onChange={() =>
                        setAktivitasBukuBesar(!aktivitasBukuBesar)
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={labaRugi} />}
                      label="Laba Rugi"
                      onChange={() => setLabaRugi(!labaRugi)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={neraca} />}
                      label="Neraca"
                      onChange={() => setNeraca(!neraca)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={neracaSaldo} />}
                      label="Neraca Saldo"
                      onChange={() => setNeracaSaldo(!neracaSaldo)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={jurnalUmum} />}
                      label="Jurnal Umum"
                      onChange={() => setJurnalUmum(!jurnalUmum)}
                    />
                  </FormGroup>
                  <Typography variant="p" sx={[spacingTop]}>
                    Utility
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={profilUser} />}
                      label="Profil User"
                      onChange={() => setProfilUser(!profilUser)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={daftarUser} />}
                      label="Daftar User"
                      onChange={() => setDaftarUser(!daftarUser)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={tutupPeriode} />}
                      label="Tutup Periode"
                      onChange={() => setTutupPeriode(!tutupPeriode)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={gantiPeriode} />}
                      label="Ganti Periode"
                      onChange={() => setGantiPeriode(!gantiPeriode)}
                    />
                  </FormGroup>
                  {user.tipeUser === "OWN/DIREKSI" && (
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={settingAkses} />}
                        label="Setting"
                        onChange={() => setSettingAkses(!settingAkses)}
                      />
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </Box>
          </Paper>
        )}
        <Box sx={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/daftarUser")}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Box>
      </Form>
      <Divider sx={dividerStyle} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default TambahUser;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataContainerCheck = {
  mt: 4,
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};
