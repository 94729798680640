import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import DatePicker from "react-datepicker";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Alert, Button, Snackbar, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const UbahJurnalUmum = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [noBukti, setNoBukti] = useState("");
  const [inputTglJurnalUmum, setInputTglJurnalUmum] = useState("");
  const [minDate, setMinDate] = useState(new Date(user.periode.periodeAwal));

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    findDefaultDate();
    getJurnalUmumById();
  }, []);

  const findDefaultDate = async () => {
    let newPeriodeAwal = new Date(user.periode.periodeAwal);
    let newPeriodeAkhir = new Date(user.periode.periodeAkhir);
    let newToday = new Date();

    let isDateBetween =
      newToday >= newPeriodeAwal && newToday <= newPeriodeAkhir;

    if (isDateBetween) {
      // Default Date Today
      if (user.tipeUser === "ADMIN") {
        setMinDate(new Date());
      }
      setInputTglJurnalUmum(new Date());
    }
  };

  const getJurnalUmumById = async () => {
    setLoading(true);
    const pickedJurnalUmum = await axios.post(`${tempUrl}/jurnalUmums/${id}`, {
      id: user._id,
      token: user.token,
    });
    setNoBukti(pickedJurnalUmum.data.noBukti);
    setInputTglJurnalUmum(new Date(pickedJurnalUmum.data.tglJurnalUmum));
    setLoading(false);
  };

  const updateJurnalUmum = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/updateJurnalUmum/${id}`, {
          tglJurnalUmum: inputTglJurnalUmum,
          tglInput: current_date,
          jamInput: current_time,
          userInput: user.username,
          id: user._id,
          token: user.token,
        });
        const jurnalUmumChilds = await axios.post(
          `${tempUrl}/jurnalUmumsChildByNoBukti`,
          {
            noBukti,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          }
        );
        for (let jurnalUmumChild of jurnalUmumChilds.data) {
          await axios.post(
            `${tempUrl}/updateJurnalUmumChild/${jurnalUmumChild._id}`,
            {
              tglJurnalUmum: inputTglJurnalUmum,
              tglInput: current_date,
              jamInput: current_time,
              userInput: user.username,
              id: user._id,
              token: user.token,
              kodeCabang: user.cabang.id,
            }
          );
        }
        setLoading(false);
        navigate(`/daftarJurnalUmum/jurnalUmum/${id}`);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Jurnal Umum</h5>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <hr />
      <Card>
        <Card.Header>Jurnal Umum</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateJurnalUmum}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    No. Bukti :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control required value={noBukti} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="3" style={textRight}>
                    Tanggal <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="9">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={inputTglJurnalUmum}
                      minDate={minDate}
                      maxDate={new Date(user.periode.periodeAkhir)}
                      customInput={<Form.Control required />}
                      onChange={(date) => setInputTglJurnalUmum(date)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/daftarJurnalUmum/jurnalUmum/${id}`)}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Ubah
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahJurnalUmum;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const subTitleText = {
  fontWeight: "900",
};

const dialogContainer = {
  display: "flex",
  flexDirection: "column",
  padding: 4,
  width: "800px",
};

const dialogWrapper = {
  width: "100%",
  marginTop: 2,
};

const colorRed = {
  color: "red",
};
