import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Colors } from "../../../constants/styles";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Autocomplete,
  Snackbar,
  Alert,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "@mui/icons-material/Save";

const TambahBeliChild = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [kodeTipe, setKodeTipe] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [noRangka2, setNoRangka2] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [noMesin2, setNoMesin2] = useState("");
  let [nopol, setNopol] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [alamatStnk, setAlamatStnk] = useState("");
  const [inputTglStnk, setInputTglStnk] = useState(new Date());
  const [jenisABeli, setJenisABeli] = useState("");
  const [ppnBeli, setPpnBeli] = useState("");
  const [hargaSatuan, setHargaSatuan] = useState("");
  const [ppnABeli, setPpnABeli] = useState("");
  const [tanggalBeli, setTanggalBeli] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [kodeSupplier, setKodeSupplier] = useState("");
  const [namaSupplier, setNamaSupplier] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [tipes, setTipes] = useState([]);
  const [warnas, setWarnas] = useState([]);
  const [belis, setBelis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAlertNopol, setOpenAlertNopol] = useState(false);
  const [openAlertNorang, setOpenAlertNorang] = useState(false);

  const [validated, setValidated] = useState(false);
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleClickOpenAlertNopol = () => {
    setOpenAlertNopol(true);
  };

  const handleCloseAlertNopol = () => {
    setOpenAlertNopol(false);
  };

  const handleClickOpenAlertNorang = () => {
    setOpenAlertNorang(true);
  };

  const handleCloseAlertNorang = () => {
    setOpenAlertNorang(false);
  };

  const tipeOptions = tipes.map((tipe) => ({
    label: `${tipe.kodeTipe} | ${tipe.namaTipe}`,
  }));

  const warnaOptions = warnas.map((warna) => ({
    label: `${warna.namaWarna}`,
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getTipes();
    getWarnas();
    getBelis();
    getTipeBeli();
  }, []);

  const getTipes = async () => {
    setLoading(true);
    const allTipes = await axios.post(`${tempUrl}/tipes`, {
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    setTipes(allTipes.data);
    setLoading(false);
  };

  const getTipe = async (idTipe) => {
    const allTipesByKode = await axios.post(`${tempUrl}/tipesByKode`, {
      kodeTipe: idTipe,
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    if (allTipesByKode.data) {
      setNoRangka(allTipesByKode.data.noRangka);
      setNoMesin(allTipesByKode.data.noMesin);
    }
    setKodeTipe(idTipe);
  };

  const getTipeBeli = async () => {
    setLoading(true);
    const allBelis = await axios.post(`${tempUrl}/belis/${id}`, {
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    setJenisABeli(allBelis.data.jenisBeli);
    setPpnBeli(allBelis.data.isPpnBeli);
    setTanggalBeli(allBelis.data.tanggalBeli);
    setSupplierId(allBelis.data.supplier._id);
    setKodeSupplier(allBelis.data.supplier.kodeSupplier);
    setNamaSupplier(allBelis.data.supplier.namaSupplier);

    if (allBelis.data.jenisBeli === "BARU") {
      setInputTglStnk(null);
    }
    setLoading(false);
  };

  const getWarnas = async () => {
    setLoading(true);
    const allWarnas = await axios.post(`${tempUrl}/warnas`, {
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    setWarnas(allWarnas.data);
    setLoading(false);
  };

  const getBelis = async () => {
    setLoading(true);
    const pickedBeli = await axios.post(`${tempUrl}/belis/${id}`, {
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    setBelis(pickedBeli.data);
    setLoading(false);
  };

  const saveBeliChild = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    let tglStnk;
    if (inputTglStnk) {
      tglStnk =
        inputTglStnk?.getDate() +
        "-" +
        (inputTglStnk?.getMonth() + 1) +
        "-" +
        inputTglStnk?.getFullYear();
    }
    // Get Beli
    const response = await axios.post(`${tempUrl}/belis/${id}`, {
      kodeCabang: user.cabang._id,
      id: user._id,
      token: user.token,
    });
    // Get Tipe/Merk
    const getTipe = await axios.post(`${tempUrl}/tipesByKode`, {
      kodeCabang: user.cabang._id,
      kodeTipe,
      id: user._id,
      token: user.token,
    });

    // Check Nopol
    let tempDaftarStokByNopol = await axios.post(
      `${tempUrl}/daftarStoksByNopol`,
      {
        nopol,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    let adaNopolDanBelumTerjual =
      tempDaftarStokByNopol.data &&
      tempDaftarStokByNopol.data.tanggalJual === null;
    if (adaNopolDanBelumTerjual && jenisABeli !== "BARU") {
      // Ada Nopol sama dan Belum Terjual, tidak boleh tambah Daftar Stok
      handleClickOpenAlertNopol();
      return;
    }

    // Check Norang
    let tempDaftarStokByNorang = await axios.post(
      `${tempUrl}/daftarStoksByNorang`,
      {
        noRangka: `${noRangka}${noRangka2}`,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );
    let adaNorangDanBelumTerjual =
      tempDaftarStokByNorang.data &&
      tempDaftarStokByNorang.data.tanggalJual === null;
    if (adaNorangDanBelumTerjual) {
      // Ada sama di Daftar Stok
      handleClickOpenAlertNorang();
      return;
    }

    let tempTglStnk = null;
    if (tglStnk) {
      var datePartsTglStnk = tglStnk.split("-");
      tempTglStnk = new Date(
        `${+datePartsTglStnk[2]}-
        ${(datePartsTglStnk[1] - 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}-
        ${(+datePartsTglStnk[0]).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}`
      );
    }

    if (response.data.jenisBeli === "BARU") {
      if (
        kodeTipe.length === 0 ||
        tahun.length === 0 ||
        namaWarna.length === 0 ||
        hargaSatuan.length === 0
      ) {
        setError(true);
        setOpen(!open);
      } else {
        try {
          setLoading(true);
          // Get Beli
          const getBeli = await axios.post(`${tempUrl}/belis/${id}`, {
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          });
          // Save Daftar Stok
          const tempDaftarStok = await axios.post(`${tempUrl}/saveDaftarStok`, {
            noBeli: belis.noBeli,
            tanggalBeli,
            supplierId,
            kodeSupplier,
            namaSupplier,
            merk: getTipe.data.merk,
            tipe: getTipe.data.kodeTipe,
            namaTipe: getTipe.data.namaTipe,
            noRangka: `${noRangka}${noRangka2}`,
            noMesin: `${noMesin}${noMesin2}`,
            nopol,
            tahun,
            namaWarna,
            namaStnk,
            alamatStnk,
            tglStnk: tempTglStnk,
            jenisBeli: jenisABeli,
            hargaSatuan: hargaSatuan.replace(/,/g, ""),
            ppnABeli,
            COA: response.data.COA._id,
            tglInput: current_date,
            jamInput: current_time,
            userInput: user.username,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          });
          // Update Beli
          await axios.post(`${tempUrl}/updateBeli/${id}`, {
            jumlahBeli:
              parseInt(getBeli.data.jumlahBeli) +
              parseInt(hargaSatuan.replace(/,/g, "")),
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/daftarBeli/beli/${id}`);
        } catch (error) {
          console.log(error);
        }
      }
    } else if (response.data.jenisBeli === "BEKAS") {
      if (
        kodeTipe.length === 0 ||
        tahun.length === 0 ||
        namaWarna.length === 0 ||
        nopol.length === 0 ||
        namaStnk.length === 0 ||
        alamatStnk.length === 0 ||
        inputTglStnk === null ||
        hargaSatuan.length === 0
      ) {
        setError(true);
        setOpen(!open);
      } else {
        try {
          setLoading(true);
          // Get Beli
          const getBeli = await axios.post(`${tempUrl}/belis/${id}`, {
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          });
          // Save Daftar Stok
          const tempDaftarStok = await axios.post(`${tempUrl}/saveDaftarStok`, {
            noBeli: belis.noBeli,
            tanggalBeli,
            supplierId,
            kodeSupplier,
            namaSupplier,
            merk: getTipe.data.merk,
            tipe: `${getTipe.data.kodeTipe}`,
            namaTipe: getTipe.data.namaTipe,
            noRangka: `${noRangka}${noRangka2}`,
            noMesin: `${noMesin}${noMesin2}`,
            nopol,
            tahun,
            namaWarna,
            namaStnk,
            alamatStnk,
            tglStnk: tempTglStnk,
            jenisBeli: jenisABeli,
            hargaSatuan: hargaSatuan.replace(/,/g, ""),
            ppnABeli,
            COA: response.data.COA._id,
            tglInput: current_date,
            jamInput: current_time,
            userInput: user.username,
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          });
          // Update Beli
          await axios.post(`${tempUrl}/updateBeli/${id}`, {
            jumlahBeli:
              parseInt(getBeli.data.jumlahBeli) +
              parseInt(hargaSatuan.replace(/,/g, "")),
            kodeCabang: user.cabang._id,
            id: user._id,
            token: user.token,
          });
          setLoading(false);
          navigate(`/daftarBeli/beli/${id}`);
        } catch (error) {
          console.log(error);
        }
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Pembelian</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Tambah Barang Beli
      </Typography>
      <Dialog
        open={openAlertNopol}
        onClose={handleCloseAlertNopol}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Nopol Sama`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Nopol ${nopol} sudah ada dan belum terjual, ganti Nopol!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertNopol}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAlertNorang}
        onClose={handleCloseAlertNorang}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Data No Rangka Sama`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`No Rangka ${noRangka}${noRangka2} sudah ada dan belum terjual, ganti No Rangka!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertNorang}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={saveBeliChild}>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Kode Tipe <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={tipeOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeTipe.length === 0 && true}
                            helperText={
                              error &&
                              kodeTipe.length === 0 &&
                              "Kode Tipe harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => {
                          if (value) {
                            getTipe(`${value.split(" |", 1)[0]}`);
                          } else {
                            setNoRangka("");
                            setNoMesin("");
                          }
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tahun <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        value={tahun}
                        onChange={(e) => {
                          if (e.target.value.toString().length <= 4) {
                            setTahun(e.target.value.toUpperCase());
                          }
                        }}
                        required
                        isInvalid={tahun.length != 4 && true}
                      />
                      <Form.Control.Feedback type="invalid">
                        Tahun harus diisi dan harus 4 digit angka!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nama Warna <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={warnaOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && namaWarna.length === 0 && true}
                            helperText={
                              error &&
                              namaWarna.length === 0 &&
                              "Nama Warna harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setNamaWarna(value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No Rangka :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        value={noRangka}
                        placeholder="No Rangka"
                        disabled
                      />
                    </Col>
                    <Col sm={3}>
                      <Form.Control
                        value={noRangka2}
                        placeholder="(Tambahan)"
                        onChange={(e) =>
                          setNoRangka2(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No Mesin :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        value={noMesin}
                        placeholder="No Mesin"
                        disabled
                      />
                    </Col>
                    <Col sm={3}>
                      <Form.Control
                        value={noMesin2}
                        placeholder="(Tambahan)"
                        onChange={(e) =>
                          setNoMesin2(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Jenis :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={jenisABeli} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nopol{" "}
                      {jenisABeli === "BEKAS" && <b style={colorRed}>*</b>} :
                    </Form.Label>
                    <Col sm="9">
                      {jenisABeli === "BARU" ? (
                        <Form.Control value={nopol} disabled />
                      ) : (
                        <Form.Control
                          value={nopol}
                          required
                          onChange={(e) =>
                            setNopol(e.target.value.toUpperCase())
                          }
                        />
                      )}
                      <Form.Control.Feedback type="invalid">
                        Nopol harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tanggal STNK{" "}
                      {jenisABeli === "BEKAS" && <b style={colorRed}>*</b>} :
                    </Form.Label>
                    <Col sm="9">
                      {jenisABeli === "BARU" ? (
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={inputTglStnk}
                          readOnly={true}
                          customInput={
                            <TextField
                              sx={{
                                backgroundColor: Colors.grey400,
                                width: "100%",
                              }}
                              size="small"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          }
                        />
                      ) : (
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={inputTglStnk}
                          onChange={(e) => setInputTglStnk(e)}
                          customInput={
                            <TextField
                              error={error && inputTglStnk === null && true}
                              helperText={
                                error &&
                                inputTglStnk === null &&
                                "Tanggal Stnk harus diisi!"
                              }
                              sx={{ width: "100%" }}
                              size="small"
                            />
                          }
                        />
                      )}
                      <Form.Control.Feedback type="invalid">
                        Tgl. STNK harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nama STNK{" "}
                      {jenisABeli === "BEKAS" && <b style={colorRed}>*</b>} :
                    </Form.Label>
                    <Col sm="9">
                      {jenisABeli === "BARU" ? (
                        <Form.Control value={namaStnk} disabled />
                      ) : (
                        <Form.Control
                          value={namaStnk}
                          required
                          onChange={(e) =>
                            setNamaStnk(e.target.value.toUpperCase())
                          }
                        />
                      )}
                      <Form.Control.Feedback type="invalid">
                        Nama STNK harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Alamat STNK{" "}
                      {jenisABeli === "BEKAS" && <b style={colorRed}>*</b>} :
                    </Form.Label>
                    <Col sm="9">
                      {jenisABeli === "BARU" ? (
                        <Form.Control value={alamatStnk} disabled />
                      ) : (
                        <Form.Control
                          value={alamatStnk}
                          required
                          onChange={(e) =>
                            setAlamatStnk(e.target.value.toUpperCase())
                          }
                        />
                      )}
                      <Form.Control.Feedback type="invalid">
                        Alamat STNK harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Harga Satuan <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={hargaSatuan}
                        onChange={(e) => {
                          let tempNum;
                          let isNumNan = isNaN(
                            parseInt(e.target.value.replace(/,/g, ""), 10)
                          );
                          if (isNumNan) {
                            tempNum = "";
                          } else {
                            tempNum = parseInt(
                              e.target.value.replace(/,/g, ""),
                              10
                            ).toLocaleString("en-US");
                          }
                          setHargaSatuan(tempNum);
                          if (ppnBeli) {
                            setPpnABeli(e.target.value * setting.PPN);
                          } else {
                            setPpnABeli(0);
                          }
                        }}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Harga Satuan harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      PPN :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={ppnABeli} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={textFieldStyle}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/daftarBeli/beli/${id}`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<SaveIcon />} type="submit">
              Simpan
            </Button>
          </Box>
        </Form>
      </Paper>
      <Divider sx={dividerStyle} />
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default TambahBeliChild;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const textFieldContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const textFieldStyle = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const hargaContainer = {
  marginTop: 2.5,
};

const hargaText = {
  fontWeight: "500",
  color: "gray",
};

const hargaTextField = {
  display: "flex",
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const contentContainer = {
  p: 3,
  pt: 1,
  mt: 2,
  backgroundColor: Colors.grey100,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};
