import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { Loader, ScrollToTop } from "../../../components";
import { Colors } from "../../../constants/styles";
import { Box, Typography, Divider, Button, Paper } from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";

const UbahPenarikan = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);

  // Customer
  const [noPenarikan, setNoPenarikan] = useState(""); // I
  const [tglPenarikan, setTglPenarikan] = useState("");
  const [noJual, setNoJual] = useState(""); // I

  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngFormatted, setTglAngFormatted] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  // Motor
  const [tipe, setTipe] = useState("");
  const [noRangka, setNoRangka] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [nopol, setNopol] = useState("");
  // Biaya
  const [tglJatuhTempo, setTglJatuhTempo] = useState("");
  const [tglJatuhTempoFormatted, setTglJatuhTempoFormatted] = useState("");
  const [sisaBulanPenarikan, setSisaBulanPenarikan] = useState(""); // I
  const [tenor, setTenor] = useState(""); // I
  const [ketPenarikan, setKetPenarikan] = useState(""); // I

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    getPenarikanById();
  }, [id]);

  const getPenarikanById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/penarikans/${id}`, {
        id: user._id,
        token: user.token,
      });
      setNoPenarikan(response.data.noPenarikan);
      setTglPenarikan(formatDate(response.data.tglPenarikan));
      setNoJual(response.data.noJual);
      setSisaBulanPenarikan(response.data.sisaBulanPenarikan);
      setKetPenarikan(response.data.ketPenarikan);

      setNamaRegister(response.data.idJual.namaRegister);
      setAlmRegister(response.data.idJual.almRegister);
      setTglAng(response.data.idJual.tglJatuhTempo);
      setTglAngFormatted(formatDate(response.data.idJual.tglJatuhTempo));
      setKodeKelurahan(
        `${response.data.idJual.kodeKelurahan.kodeKelurahan} - ${response.data.idJual.kodeKelurahan.namaKelurahan}`
      );
      setTlpRegister(response.data.idJual.tlpRegister);
      setTipe(response.data.idJual.tipe);
      setNoRangka(response.data.idJual.noRangka);
      setNamaWarna(response.data.idJual.namaWarna);
      setTahun(response.data.idJual.tahun);
      setNopol(response.data.idJual.nopol);
      setTglJatuhTempo(response.data.idJual.tglJatuhTempo);
      setTenor(response.data.idJual.tenor - response.data.sisaBulanPenarikan);
      setTglJatuhTempoFormatted(formatDate(response.data.idJual.tglJatuhTempo));
    }
    setLoading(false);
  };

  const updatePenarikan = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation = false;
    if (isFailedValidation) {
      setError(true);
    } else {
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/updatePenarikan/${id}`, {
          ketPenarikan,
          tglUpdate: current_date,
          jamUpdate: current_time,
          userUpdate: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/penarikan/${id}`);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Ubah Penarikan
      </Typography>
      <Divider sx={dividerStyle} />
      <Paper sx={contentContainer} elevation={12}>
        <Form noValidate validated={validated} onSubmit={updatePenarikan}>
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA CUSTOMER
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Penarikan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noPenarikan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Penarikan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglPenarikan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tlpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kelurahan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeKelurahan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Angsuran :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglAngFormatted} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <hr />
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA MOTOR
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tipe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Rangka :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRangka} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tahun / Warna :
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control value={tahun} disabled />
                    </Col>
                    <Col sm={4}>
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <hr />
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA PENARIKAN
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Jatuh Tempo:
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglJatuhTempoFormatted} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Sisa Bulan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={sisaBulanPenarikan.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tenor :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        value={tenor.toLocaleString("en-US")}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Keterangan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={ketPenarikan}
                        onChange={(e) => {
                          setKetPenarikan(e.target.value.toUpperCase());
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}></Box>
          </Box>
          <Divider sx={dividerStyle} />
          <Box sx={spacingTop}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/penarikan/${id}`)}
              sx={{ marginRight: 2 }}
            >
              {"< Kembali"}
            </Button>
            <Button variant="contained" startIcon={<EditIcon />} type="submit">
              Ubah
            </Button>
          </Box>
        </Form>
      </Paper>
    </Box>
  );
};

export default UbahPenarikan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
  backgroundColor: Colors.grey100,
};
