import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Button,
  ButtonGroup,
  Autocomplete,
} from "@mui/material";
import { Loader, usePagination } from "../../../components";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const UbahAngsuranJual = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const idJual = location.pathname.split("/")[5];

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  // Show Data Jual
  const [jualId, setJualId] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [noJual, setNoJual] = useState("");
  const [tanggalJual, setTanggalJual] = useState("");
  const [nopol, setNopol] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeSurveyorLama, setKodeSurveyorLama] = useState("");
  const [kodeSurveyorAsli, setKodeSurveyorAsli] = useState("");
  const [tipe, setTipe] = useState("");
  const [ketBlacklist, setKetBlacklist] = useState("");

  const [tglSpTerakhir, setTglSpTerakhir] = useState("");
  const [tglMdTerakhir, setTglMdTerakhir] = useState("");
  const [tglStTerakhir, setTglStTerakhir] = useState("");
  const [spKe, setSpKe] = useState("");
  const [surveyors, setSurveyors] = useState([]);
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const surveyorOptions = surveyors.map((surveyor) => ({
    value: surveyor.kodeSurveyor,
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  useEffect(() => {
    getJualById();
    getSurveyor();
  }, []);

  const getSurveyor = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getJualById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/jualByNoJual`, {
        noJual: id,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      });
      setJualId(response.data._id);
      setNamaRegister(response.data.namaRegister);
      setNoJual(response.data.noJual);
      setTanggalJual(formatDate(response.data.tglAng));
      setNopol(response.data.nopol);
      setAlmRegister(response.data.almRegister);
      setKodeSurveyor(
        `${response.data.kodeSurveyor.kodeSurveyor} - ${response.data.kodeSurveyor.namaSurveyor}`
      );
      setKodeSurveyorLama(
        `${response.data.kodeSurveyor.kodeSurveyor} - ${response.data.kodeSurveyor.namaSurveyor}`
      );
      setKodeSurveyorAsli(response.data.kodeSurveyorAsli);
      setTipe(response.data.tipe);
      setKetBlacklist(response.data.ketBlacklist);
      setTglSpTerakhir(formatDate(response.data.tglSpTerakhir));
      setTglMdTerakhir(formatDate(response.data.tglMdTerakhir));
      setTglStTerakhir(formatDate(response.data.tglStTerakhir));
      setSpKe(response.data.spKe);
    }
  };

  const updateJual = async (e) => {
    e.preventDefault();
    var date = new Date();
    var current_date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    var current_time =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

    let isFailedValidation = kodeSurveyor.length === 0;
    if (isFailedValidation) {
      setError(true);
      setOpen(!open);
    } else {
      try {
        setLoading(true);
        const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
          kodeSurveyor: kodeSurveyor.split(" -", 1)[0],
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        });
        await axios.post(`${tempUrl}/updateJualSurveyor/${idJual}`, {
          kodeSurveyor: tempSurveyor.data._id,
          ketBlacklist,
          tglUpdate: current_date,
          jamUpdate: current_time,
          userUpdate: user.username,
          kodeCabang: user.cabang._id,
          id: user._id,
          token: user.token,
        });
        setLoading(false);
        navigate(`/daftarAngsuran/angsuran/${id}`);
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(`/daftarAngsuran/angsuran/${id}`)}
        sx={{ marginLeft: 2, marginTop: 4 }}
      >
        {"< Kembali"}
      </Button>
      <Box>
        <Typography color="#757575">Piutang</Typography>
        <Typography variant="h4" sx={subTitleText}>
          Angsuran Ubah Surveyor Jual
        </Typography>
        <Divider sx={[dividerStyle, { marginBottom: 2 }]} />
        <Form noValidate validated={validated} onSubmit={updateJual}>
          <Box sx={textFieldContainer}>
            <Box sx={textFieldWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nama Register :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tanggal Jual :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tanggalJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Alamat :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Surveyor <b style={colorRed}>*</b> :
                    </Form.Label>
                    <Col sm="9">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={surveyorOptions}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            error={error && kodeSurveyor.length === 0 && true}
                            helperText={
                              error &&
                              kodeSurveyor.length === 0 &&
                              "Kode Surveyor harus diisi!"
                            }
                            {...params}
                          />
                        )}
                        onInputChange={(e, value) => setKodeSurveyor(value)}
                        inputValue={kodeSurveyor}
                        onChange={(e, value) => setKodeSurveyorLama(value)}
                        value={kodeSurveyorLama}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Surveyor Asli :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={`${kodeSurveyorAsli.kodeSurveyor} - ${kodeSurveyorAsli.namaSurveyor}`}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[textFieldWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tgl. SP Terakhir :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tglSpTerakhir} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tgl. MD Terakhir :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tglSpTerakhir} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tgl. ST Terakhir :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tglStTerakhir} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      SP Ke- :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={spKe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control value={tipe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="3" style={textRight}>
                      Ket. Blacklist :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={ketBlacklist}
                        onChange={(e) =>
                          setKetBlacklist(e.target.value.toUpperCase())
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Box sx={spacingTop}>
            <Button variant="contained" startIcon={<EditIcon />} type="submit">
              Ubah
            </Button>
          </Box>
        </Form>
      </Box>
    </>
  );
};

export default UbahAngsuranJual;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  pt: 4,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const colorRed = {
  color: "red",
};
