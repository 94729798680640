import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import {
  Box,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  Divider,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const Point3Bulan = () => {
  const tableRef = useRef(null);
  const { user, setting, dispatch } = useContext(AuthContext);
  var date = new Date();
  let [inputDariTgl, setInputDariTgl] = useState(date);
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  const [jenisSurveyor, setJenisSurveyor] = useState("SEMUA");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [surveyors, setSurveyors] = useState([]);
  const [tunggakan, setTunggakan] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");

  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const handleChangeLaporan = (event) => {
    setJenisSurveyor(event.target.value);
  };

  const columnsRekap = [
    { title: "No", field: "no" }, //0
    { title: "Surveyor", field: "noJual" }, //1
    { title: "", field: "" }, //2
    { title: "", field: "" }, //3
    { title: "", field: "" }, //4
    { title: "", field: "" }, //5
    { title: "3", field: "ke3" }, //6
    { title: "6", field: "ke6" }, //7
    { title: "9", field: "ke9" }, //8
    { title: "12", field: "ke12" }, //9
    { title: "15", field: "ke15" }, //10
    { title: "18", field: "ke18" }, //11
    { title: "21", field: "ke21" }, //12
    { title: "24", field: "ke24" }, //13
    { title: "27", field: "ke27" }, //14
    { title: "30", field: "ke30" }, //15
    { title: "33", field: "ke33" }, //16
    { title: "36", field: "ke36" }, //17
    { title: "P", field: "p" }, //18
    { title: "Total", field: "total" }, //19
  ];

  const columns = [
    { title: "No", field: "no" }, //0
    { title: "Kontrak", field: "noJual" }, //1
    { title: "Tanggal", field: "tglBayarFormatted" }, //2
    { title: "Nama", field: "namaRegister" }, //3
    { title: "JW", field: "tenor" }, //4
    { title: "Ang", field: "angPerBulan" }, //5
    { title: "3", field: "ke3" }, //6
    { title: "6", field: "ke6" }, //7
    { title: "9", field: "ke9" }, //8
    { title: "12", field: "ke12" }, //9
    { title: "15", field: "ke15" }, //10
    { title: "18", field: "ke18" }, //11
    { title: "21", field: "ke21" }, //12
    { title: "24", field: "ke24" }, //13
    { title: "27", field: "ke27" }, //14
    { title: "30", field: "ke30" }, //15
    { title: "33", field: "ke33" }, //16
    { title: "36", field: "ke36" }, //17
    { title: "P", field: "p" }, //18
    { title: "Total", field: "total" }, //19
  ];

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  useEffect(() => {
    getSurveyor();
  }, []);

  const getSurveyor = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(response.data);
    setLoading(false);
  };

  const downloadPdfRekap = async () => {
    setLoading(true);

    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);

    let tempPersenLoading = parseInt(persenLoading);
    tempPersenLoading += 10;
    setPersenLoading(tempPersenLoading.toString());

    const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
      kodeSurveyor,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "5000");

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "10000");

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "15000");

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "20000");

    const response = await axios.post(
      `${tempUrl}/penerimaansForLaporanPoint3Bulan`,
      {
        dariTgl: inputDariTgl,
        sampaiTgl: inputSampaiTgl,
        kodeSurveyor: tempSurveyor.data ? tempSurveyor.data._id : null,
        jenisSurveyor: tempSurveyor.data
          ? tempSurveyor.data.jenisSurveyor
          : jenisSurveyor,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );

    let tempP = 0;
    let tempPp = 0;
    let tempPl = 0;
    let tempPk = 0;
    let tempLebihDariSamaDengan90 = 0;
    let tempTotal = 0;

    for (let result of response.data) {
      tempP += result.p;
      tempPp += result.pp;
      tempPl += result.pl;
      tempPk += result.pk;
      tempLebihDariSamaDengan90 += result.lebihDariSamaDengan90;
      tempTotal += result.total;
    }

    tempPersenLoading += 10;
    setPersenLoading(tempPersenLoading.toString());

    let tempHeight = 35;

    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF("l", "mm", [410, 210]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Point 3 Bulan`, 200, 30);
    doc.setFontSize(10);
    doc.text(`Dari Tanggal : ${dariTgl}`, 15, 35);
    doc.text(`Sampai Tanggal : ${sampaiTgl}`, 15, 40);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      200
    );
    doc.setFontSize(12);

    function alignCol(data) {
      var col = data.column.index;
      if (col >= 4) {
        data.cell.styles.halign = "right";
      }
    }

    let tempTotalKe3 = 0;
    let tempTotalKe6 = 0;
    let tempTotalKe9 = 0;
    let tempTotalKe12 = 0;
    let tempTotalKe15 = 0;
    let tempTotalKe18 = 0;
    let tempTotalKe21 = 0;
    let tempTotalKe24 = 0;
    let tempTotalKe27 = 0;
    let tempTotalKe30 = 0;
    let tempTotalKe33 = 0;
    let tempTotalKe36 = 0;
    let tempTotalP = 0;
    let tempGrandTotal = 0;

    if (tempSurveyor.data) {
      // Cetak 1 Surveyor
      tempHeight = 50;

      doc.line(15, tempHeight, 405, tempHeight);
      doc.line(25, tempHeight, 25, tempHeight + 8);
      doc.line(195, tempHeight, 195, tempHeight + 8);
      doc.line(210, tempHeight, 210, tempHeight + 8);
      doc.line(225, tempHeight, 225, tempHeight + 8);
      doc.line(240, tempHeight, 240, tempHeight + 8);
      doc.line(255, tempHeight, 255, tempHeight + 8);
      doc.line(270, tempHeight, 270, tempHeight + 8);
      doc.line(285, tempHeight, 285, tempHeight + 8);
      doc.line(300, tempHeight, 300, tempHeight + 8);
      doc.line(315, tempHeight, 315, tempHeight + 8);
      doc.line(330, tempHeight, 330, tempHeight + 8);
      doc.line(345, tempHeight, 345, tempHeight + 8);
      doc.line(360, tempHeight, 360, tempHeight + 8);
      doc.line(375, tempHeight, 375, tempHeight + 8);
      doc.line(388, tempHeight, 388, tempHeight + 8);

      tempHeight += 5;
      doc.setFont(undefined, "bold");
      doc.text(`NO.`, 15, tempHeight);
      doc.text(`SURVEYOR`, 30, tempHeight);
      doc.text(`3`, 200, tempHeight);
      doc.text(`6`, 215, tempHeight);
      doc.text(`9`, 230, tempHeight);
      doc.text(`12`, 245, tempHeight);
      doc.text(`15`, 260, tempHeight);
      doc.text(`18`, 275, tempHeight);
      doc.text(`21`, 290, tempHeight);
      doc.text(`24`, 305, tempHeight);
      doc.text(`27`, 320, tempHeight);
      doc.text(`30`, 335, tempHeight);
      doc.text(`33`, 350, tempHeight);
      doc.text(`36`, 365, tempHeight);
      doc.text(`P`, 380, tempHeight);
      doc.text(`Total`, 390, tempHeight);
      doc.setFont(undefined, "normal");
      tempHeight += 3;
      doc.line(15, tempHeight, 405, tempHeight);

      let tempKe3 = 0;
      let tempKe6 = 0;
      let tempKe9 = 0;
      let tempKe12 = 0;
      let tempKe15 = 0;
      let tempKe18 = 0;
      let tempKe21 = 0;
      let tempKe24 = 0;
      let tempKe27 = 0;
      let tempKe30 = 0;
      let tempKe33 = 0;
      let tempKe36 = 0;
      let tempP = 0;
      let tempTotal = 0;
      response.data[0].map((val) => {
        tempKe3 += val.ke3;
        tempKe6 += val.ke6;
        tempKe9 += val.ke9;
        tempKe12 += val.ke12;
        tempKe15 += val.ke15;
        tempKe18 += val.ke18;
        tempKe21 += val.ke21;
        tempKe24 += val.ke24;
        tempKe27 += val.ke27;
        tempKe30 += val.ke30;
        tempKe33 += val.ke33;
        tempKe36 += val.ke36;
        tempP += val.p;
        tempTotal += val.total;

        tempTotalKe3 += val.ke3;
        tempTotalKe6 += val.ke6;
        tempTotalKe9 += val.ke9;
        tempTotalKe12 += val.ke12;
        tempTotalKe15 += val.ke15;
        tempTotalKe18 += val.ke18;
        tempTotalKe21 += val.ke21;
        tempTotalKe24 += val.ke24;
        tempTotalKe27 += val.ke27;
        tempTotalKe30 += val.ke30;
        tempTotalKe33 += val.ke33;
        tempTotalKe36 += val.ke36;
        tempTotalP += val.p;
        tempGrandTotal += val.total;
      });
      if (tempHeight > 192) {
        doc.addPage();
        tempHeight = 10;
      }

      doc.line(25, tempHeight, 25, tempHeight + 8);
      doc.line(195, tempHeight, 195, tempHeight + 8);
      doc.line(210, tempHeight, 210, tempHeight + 8);
      doc.line(225, tempHeight, 225, tempHeight + 8);
      doc.line(240, tempHeight, 240, tempHeight + 8);
      doc.line(255, tempHeight, 255, tempHeight + 8);
      doc.line(270, tempHeight, 270, tempHeight + 8);
      doc.line(285, tempHeight, 285, tempHeight + 8);
      doc.line(300, tempHeight, 300, tempHeight + 8);
      doc.line(315, tempHeight, 315, tempHeight + 8);
      doc.line(330, tempHeight, 330, tempHeight + 8);
      doc.line(345, tempHeight, 345, tempHeight + 8);
      doc.line(360, tempHeight, 360, tempHeight + 8);
      doc.line(375, tempHeight, 375, tempHeight + 8);
      doc.line(388, tempHeight, 388, tempHeight + 8);
      tempHeight += 5;

      let no = 1;
      doc.text(`${no}`, 20, tempHeight, {
        align: "right",
      });
      doc.text(
        `Surveyor : ${`${kodeSurveyor} - ${
          tempSurveyor.data ? tempSurveyor.data.namaSurveyor : ""
        }`}`,
        30,
        tempHeight
      );
      doc.text(`${tempKe3}`, 205, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe6}`, 220, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe9}`, 235, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe12}`, 250, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe15}`, 265, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe18}`, 280, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe21}`, 295, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe24}`, 310, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe27}`, 325, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe30}`, 340, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe33}`, 355, tempHeight, {
        align: "right",
      });
      doc.text(`${tempKe36}`, 370, tempHeight, {
        align: "right",
      });
      doc.text(`${tempP}`, 385, tempHeight, {
        align: "right",
      });
      doc.text(`${tempTotal}`, 405, tempHeight, {
        align: "right",
      });
      tempHeight += 3;
      doc.line(15, tempHeight, 405, tempHeight);
    } else {
      // Cetak Semua Surveyor
      let ifNoPenerimaan = false;
      tempHeight = 50;

      doc.line(15, tempHeight, 405, tempHeight);
      doc.line(25, tempHeight, 25, tempHeight + 8);
      doc.line(195, tempHeight, 195, tempHeight + 8);
      doc.line(210, tempHeight, 210, tempHeight + 8);
      doc.line(225, tempHeight, 225, tempHeight + 8);
      doc.line(240, tempHeight, 240, tempHeight + 8);
      doc.line(255, tempHeight, 255, tempHeight + 8);
      doc.line(270, tempHeight, 270, tempHeight + 8);
      doc.line(285, tempHeight, 285, tempHeight + 8);
      doc.line(300, tempHeight, 300, tempHeight + 8);
      doc.line(315, tempHeight, 315, tempHeight + 8);
      doc.line(330, tempHeight, 330, tempHeight + 8);
      doc.line(345, tempHeight, 345, tempHeight + 8);
      doc.line(360, tempHeight, 360, tempHeight + 8);
      doc.line(375, tempHeight, 375, tempHeight + 8);
      doc.line(388, tempHeight, 388, tempHeight + 8);

      tempHeight += 5;
      doc.setFont(undefined, "bold");
      doc.text(`NO.`, 15, tempHeight);
      doc.text(`SURVEYOR`, 30, tempHeight);
      doc.text(`3`, 200, tempHeight);
      doc.text(`6`, 215, tempHeight);
      doc.text(`9`, 230, tempHeight);
      doc.text(`12`, 245, tempHeight);
      doc.text(`15`, 260, tempHeight);
      doc.text(`18`, 275, tempHeight);
      doc.text(`21`, 290, tempHeight);
      doc.text(`24`, 305, tempHeight);
      doc.text(`27`, 320, tempHeight);
      doc.text(`30`, 335, tempHeight);
      doc.text(`33`, 350, tempHeight);
      doc.text(`36`, 365, tempHeight);
      doc.text(`P`, 380, tempHeight);
      doc.text(`Total`, 390, tempHeight);
      doc.setFont(undefined, "normal");
      tempHeight += 3;
      doc.line(15, tempHeight, 405, tempHeight);

      let no = 1;

      for (let perSurveyor of response.data) {
        if (perSurveyor.penerimaans.length > 0) {
          ifNoPenerimaan = true;
          let tempKe3 = 0;
          let tempKe6 = 0;
          let tempKe9 = 0;
          let tempKe12 = 0;
          let tempKe15 = 0;
          let tempKe18 = 0;
          let tempKe21 = 0;
          let tempKe24 = 0;
          let tempKe27 = 0;
          let tempKe30 = 0;
          let tempKe33 = 0;
          let tempKe36 = 0;
          let tempP = 0;
          let tempTotal = 0;
          perSurveyor.penerimaans.map((val) => {
            tempKe3 += val.ke3;
            tempKe6 += val.ke6;
            tempKe9 += val.ke9;
            tempKe12 += val.ke12;
            tempKe15 += val.ke15;
            tempKe18 += val.ke18;
            tempKe21 += val.ke21;
            tempKe24 += val.ke24;
            tempKe27 += val.ke27;
            tempKe30 += val.ke30;
            tempKe33 += val.ke33;
            tempKe36 += val.ke36;
            tempP += val.p;
            tempTotal += val.total;

            tempTotalKe3 += val.ke3;
            tempTotalKe6 += val.ke6;
            tempTotalKe9 += val.ke9;
            tempTotalKe12 += val.ke12;
            tempTotalKe15 += val.ke15;
            tempTotalKe18 += val.ke18;
            tempTotalKe21 += val.ke21;
            tempTotalKe24 += val.ke24;
            tempTotalKe27 += val.ke27;
            tempTotalKe30 += val.ke30;
            tempTotalKe33 += val.ke33;
            tempTotalKe36 += val.ke36;
            tempTotalP += val.p;
            tempGrandTotal += val.total;
          });
          if (tempHeight > 192) {
            doc.addPage();
            tempHeight = 10;

            doc.line(15, tempHeight, 405, tempHeight);
            doc.line(25, tempHeight, 25, tempHeight + 8);
            doc.line(195, tempHeight, 195, tempHeight + 8);
            doc.line(210, tempHeight, 210, tempHeight + 8);
            doc.line(225, tempHeight, 225, tempHeight + 8);
            doc.line(240, tempHeight, 240, tempHeight + 8);
            doc.line(255, tempHeight, 255, tempHeight + 8);
            doc.line(270, tempHeight, 270, tempHeight + 8);
            doc.line(285, tempHeight, 285, tempHeight + 8);
            doc.line(300, tempHeight, 300, tempHeight + 8);
            doc.line(315, tempHeight, 315, tempHeight + 8);
            doc.line(330, tempHeight, 330, tempHeight + 8);
            doc.line(345, tempHeight, 345, tempHeight + 8);
            doc.line(360, tempHeight, 360, tempHeight + 8);
            doc.line(375, tempHeight, 375, tempHeight + 8);
            doc.line(388, tempHeight, 388, tempHeight + 8);

            tempHeight += 5;
            doc.setFont(undefined, "bold");
            doc.text(`NO.`, 15, tempHeight);
            doc.text(`SURVEYOR`, 30, tempHeight);
            doc.text(`3`, 200, tempHeight);
            doc.text(`6`, 215, tempHeight);
            doc.text(`9`, 230, tempHeight);
            doc.text(`12`, 245, tempHeight);
            doc.text(`15`, 260, tempHeight);
            doc.text(`18`, 275, tempHeight);
            doc.text(`21`, 290, tempHeight);
            doc.text(`24`, 305, tempHeight);
            doc.text(`27`, 320, tempHeight);
            doc.text(`30`, 335, tempHeight);
            doc.text(`33`, 350, tempHeight);
            doc.text(`36`, 365, tempHeight);
            doc.text(`P`, 380, tempHeight);
            doc.text(`Total`, 390, tempHeight);
            doc.setFont(undefined, "normal");
            tempHeight += 3;
            doc.line(15, tempHeight, 405, tempHeight);
          }

          doc.line(25, tempHeight, 25, tempHeight + 8);
          doc.line(195, tempHeight, 195, tempHeight + 8);
          doc.line(210, tempHeight, 210, tempHeight + 8);
          doc.line(225, tempHeight, 225, tempHeight + 8);
          doc.line(240, tempHeight, 240, tempHeight + 8);
          doc.line(255, tempHeight, 255, tempHeight + 8);
          doc.line(270, tempHeight, 270, tempHeight + 8);
          doc.line(285, tempHeight, 285, tempHeight + 8);
          doc.line(300, tempHeight, 300, tempHeight + 8);
          doc.line(315, tempHeight, 315, tempHeight + 8);
          doc.line(330, tempHeight, 330, tempHeight + 8);
          doc.line(345, tempHeight, 345, tempHeight + 8);
          doc.line(360, tempHeight, 360, tempHeight + 8);
          doc.line(375, tempHeight, 375, tempHeight + 8);
          doc.line(388, tempHeight, 388, tempHeight + 8);
          tempHeight += 5;

          doc.text(`${no}`, 20, tempHeight, {
            align: "right",
          });
          no++;
          doc.text(
            `Surveyor : ${`${perSurveyor.kodeSurveyor} - ${perSurveyor.namaSurveyor}`}`,
            30,
            tempHeight
          );
          doc.text(`${tempKe3}`, 205, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe6}`, 220, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe9}`, 235, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe12}`, 250, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe15}`, 265, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe18}`, 280, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe21}`, 295, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe24}`, 310, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe27}`, 325, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe30}`, 340, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe33}`, 355, tempHeight, {
            align: "right",
          });
          doc.text(`${tempKe36}`, 370, tempHeight, {
            align: "right",
          });
          doc.text(`${tempP}`, 385, tempHeight, {
            align: "right",
          });
          doc.text(`${tempTotal}`, 405, tempHeight, {
            align: "right",
          });
          tempHeight += 3;
          doc.line(15, tempHeight, 405, tempHeight);
        }
      }

      if (!ifNoPenerimaan) {
        tempHeight = 55;
      }
    }
    tempHeight += 8;
    doc.text(`Total : `, 15, tempHeight);
    doc.text(`${tempTotalKe3}`, 205, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe6}`, 220, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe9}`, 235, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe12}`, 250, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe15}`, 265, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe18}`, 280, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe21}`, 295, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe24}`, 310, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe27}`, 325, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe30}`, 340, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe33}`, 355, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalKe36}`, 370, tempHeight, {
      align: "right",
    });
    doc.text(`${tempTotalP}`, 385, tempHeight, {
      align: "right",
    });
    doc.text(`${tempGrandTotal}`, 405, tempHeight, {
      align: "right",
    });
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
    setPersenLoading("0");
  };

  const downloadPdf = async () => {
    setLoading(true);

    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);

    let tempPersenLoading = parseInt(persenLoading);
    tempPersenLoading += 10;
    setPersenLoading(tempPersenLoading.toString());

    const tempSurveyor = await axios.post(`${tempUrl}/surveyorByKode`, {
      kodeSurveyor,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "5000");

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "10000");

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "15000");

    setTimeout(() => {
      tempPersenLoading += 10;
      setPersenLoading(tempPersenLoading.toString());
    }, "20000");

    const response = await axios.post(
      `${tempUrl}/penerimaansForLaporanPoint3Bulan`,
      {
        dariTgl: inputDariTgl,
        sampaiTgl: inputSampaiTgl,
        kodeSurveyor: tempSurveyor.data ? tempSurveyor.data._id : null,
        jenisSurveyor: tempSurveyor.data
          ? tempSurveyor.data.jenisSurveyor
          : jenisSurveyor,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );

    let tempP = 0;
    let tempPp = 0;
    let tempPl = 0;
    let tempPk = 0;
    let tempLebihDariSamaDengan90 = 0;
    let tempTotal = 0;

    for (let result of response.data) {
      tempP += result.p;
      tempPp += result.pp;
      tempPl += result.pl;
      tempPk += result.pk;
      tempLebihDariSamaDengan90 += result.lebihDariSamaDengan90;
      tempTotal += result.total;
    }

    tempPersenLoading += 10;
    setPersenLoading(tempPersenLoading.toString());

    let tempHeight = 35;

    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF("l", "mm", [410, 210]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Point 3 Bulan`, 200, 30);
    doc.setFontSize(10);
    doc.text(`Dari Tanggal : ${dariTgl}`, 15, 35);
    doc.text(`Sampai Tanggal : ${sampaiTgl}`, 15, 40);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      200
    );
    doc.setFontSize(12);

    function alignCol(data) {
      var col = data.column.index;
      if (col >= 4) {
        data.cell.styles.halign = "right";
      }
    }

    let tempTotalKe3 = 0;
    let tempTotalKe6 = 0;
    let tempTotalKe9 = 0;
    let tempTotalKe12 = 0;
    let tempTotalKe15 = 0;
    let tempTotalKe18 = 0;
    let tempTotalKe21 = 0;
    let tempTotalKe24 = 0;
    let tempTotalKe27 = 0;
    let tempTotalKe30 = 0;
    let tempTotalKe33 = 0;
    let tempTotalKe36 = 0;
    let tempTotalP = 0;
    let tempGrandTotal = 0;

    if (tempSurveyor.data) {
      // Cetak 1 Surveyor
      tempHeight = 50;
      doc.text(
        `Surveyor : ${`${kodeSurveyor} - ${
          tempSurveyor.data ? tempSurveyor.data.namaSurveyor : ""
        }`}`,
        15,
        50
      );
      doc.autoTable({
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 55,
        columns: columns.map((col) => ({ ...col, dataKey: col.field })),
        body: response.data[0],
        headStyles: {
          fillColor: [117, 117, 117],
          color: [0, 0, 0],
        },
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 45 },
          2: { cellWidth: 25 },
          3: { cellWidth: 70 },
          4: { cellWidth: 10 },
          5: { cellWidth: 15 },
          6: { cellWidth: 15 },
          7: { cellWidth: 15 },
          8: { cellWidth: 15 },
          9: { cellWidth: 15 },
          10: { cellWidth: 15 },
          11: { cellWidth: 15 },
          12: { cellWidth: 15 },
          13: { cellWidth: 15 },
          14: { cellWidth: 15 },
          15: { cellWidth: 15 },
          16: { cellWidth: 15 },
          17: { cellWidth: 15 },
          18: { cellWidth: 15 },
          19: { cellWidth: 20 },
          // etc
        },
        didDrawPage: (d) => {
          tempHeight = d.cursor.y + 5;
        },
        didParseCell: function (cell, data) {
          alignCol(cell, data);
        },
      });
      let tempKe3 = 0;
      let tempKe6 = 0;
      let tempKe9 = 0;
      let tempKe12 = 0;
      let tempKe15 = 0;
      let tempKe18 = 0;
      let tempKe21 = 0;
      let tempKe24 = 0;
      let tempKe27 = 0;
      let tempKe30 = 0;
      let tempKe33 = 0;
      let tempKe36 = 0;
      let tempP = 0;
      let tempTotal = 0;
      response.data[0].map((val) => {
        tempKe3 += val.ke3;
        tempKe6 += val.ke6;
        tempKe9 += val.ke9;
        tempKe12 += val.ke12;
        tempKe15 += val.ke15;
        tempKe18 += val.ke18;
        tempKe21 += val.ke21;
        tempKe24 += val.ke24;
        tempKe27 += val.ke27;
        tempKe30 += val.ke30;
        tempKe33 += val.ke33;
        tempKe36 += val.ke36;
        tempP += val.p;
        tempTotal += val.total;

        tempTotalKe3 += val.ke3;
        tempTotalKe6 += val.ke6;
        tempTotalKe9 += val.ke9;
        tempTotalKe12 += val.ke12;
        tempTotalKe15 += val.ke15;
        tempTotalKe18 += val.ke18;
        tempTotalKe21 += val.ke21;
        tempTotalKe24 += val.ke24;
        tempTotalKe27 += val.ke27;
        tempTotalKe30 += val.ke30;
        tempTotalKe33 += val.ke33;
        tempTotalKe36 += val.ke36;
        tempTotalP += val.p;
        tempGrandTotal += val.total;
      });
      if (tempHeight > 192) {
        doc.addPage();
        tempHeight = 10;
      }
      doc.line(15, tempHeight, 405, tempHeight);
      tempHeight += 4;
      doc.text(
        `Surveyor : ${`${kodeSurveyor} - ${
          tempSurveyor.data ? tempSurveyor.data.namaSurveyor : ""
        }`}`,
        15,
        tempHeight
      );
      doc.text(`${tempKe3}`, 200, tempHeight);
      doc.text(`${tempKe6}`, 215, tempHeight);
      doc.text(`${tempKe9}`, 230, tempHeight);
      doc.text(`${tempKe12}`, 245, tempHeight);
      doc.text(`${tempKe15}`, 260, tempHeight);
      doc.text(`${tempKe18}`, 275, tempHeight);
      doc.text(`${tempKe21}`, 290, tempHeight);
      doc.text(`${tempKe24}`, 305, tempHeight);
      doc.text(`${tempKe27}`, 320, tempHeight);
      doc.text(`${tempKe30}`, 335, tempHeight);
      doc.text(`${tempKe33}`, 350, tempHeight);
      doc.text(`${tempKe36}`, 365, tempHeight);
      doc.text(`${tempP}`, 380, tempHeight);
      doc.text(`${tempTotal}`, 400, tempHeight);
    } else {
      // Cetak Semua Surveyor
      let ifNoPenerimaan = false;
      let isFirstData = true;
      for (let perSurveyor of response.data) {
        if (perSurveyor.penerimaans.length > 0) {
          if (isFirstData) {
            isFirstData = false;
            tempHeight = 50;
          } else {
            doc.addPage();
            tempHeight = 20;
          }
          ifNoPenerimaan = true;
          doc.text(
            `Surveyor : ${`${perSurveyor.kodeSurveyor} - ${perSurveyor.namaSurveyor}`}`,
            15,
            tempHeight
          );
          tempHeight += 5;
          doc.autoTable({
            startY:
              doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : tempHeight,
            columns: columns.map((col) => ({ ...col, dataKey: col.field })),
            body: perSurveyor.penerimaans,
            headStyles: {
              fillColor: [117, 117, 117],
              color: [0, 0, 0],
            },
            columnStyles: {
              0: { cellWidth: 10 },
              1: { cellWidth: 45 },
              2: { cellWidth: 25 },
              3: { cellWidth: 70 },
              4: { cellWidth: 10 },
              5: { cellWidth: 15 },
              6: { cellWidth: 15 },
              7: { cellWidth: 15 },
              8: { cellWidth: 15 },
              9: { cellWidth: 15 },
              10: { cellWidth: 15 },
              11: { cellWidth: 15 },
              12: { cellWidth: 15 },
              13: { cellWidth: 15 },
              14: { cellWidth: 15 },
              15: { cellWidth: 15 },
              16: { cellWidth: 15 },
              17: { cellWidth: 15 },
              18: { cellWidth: 15 },
              19: { cellWidth: 20 },
              // etc
            },
            didDrawPage: (d) => {
              tempHeight = d.cursor.y + 5;
            },
            didParseCell: function (cell, data) {
              alignCol(cell, data);
            },
          });
          let tempKe3 = 0;
          let tempKe6 = 0;
          let tempKe9 = 0;
          let tempKe12 = 0;
          let tempKe15 = 0;
          let tempKe18 = 0;
          let tempKe21 = 0;
          let tempKe24 = 0;
          let tempKe27 = 0;
          let tempKe30 = 0;
          let tempKe33 = 0;
          let tempKe36 = 0;
          let tempP = 0;
          let tempTotal = 0;
          perSurveyor.penerimaans.map((val) => {
            tempKe3 += val.ke3;
            tempKe6 += val.ke6;
            tempKe9 += val.ke9;
            tempKe12 += val.ke12;
            tempKe15 += val.ke15;
            tempKe18 += val.ke18;
            tempKe21 += val.ke21;
            tempKe24 += val.ke24;
            tempKe27 += val.ke27;
            tempKe30 += val.ke30;
            tempKe33 += val.ke33;
            tempKe36 += val.ke36;
            tempP += val.p;
            tempTotal += val.total;

            tempTotalKe3 += val.ke3;
            tempTotalKe6 += val.ke6;
            tempTotalKe9 += val.ke9;
            tempTotalKe12 += val.ke12;
            tempTotalKe15 += val.ke15;
            tempTotalKe18 += val.ke18;
            tempTotalKe21 += val.ke21;
            tempTotalKe24 += val.ke24;
            tempTotalKe27 += val.ke27;
            tempTotalKe30 += val.ke30;
            tempTotalKe33 += val.ke33;
            tempTotalKe36 += val.ke36;
            tempTotalP += val.p;
            tempGrandTotal += val.total;
          });
          if (tempHeight > 192) {
            doc.addPage();
            tempHeight = 20;
          }
          doc.line(15, tempHeight, 405, tempHeight);
          tempHeight += 4;
          doc.text(
            `Surveyor : ${`${perSurveyor.kodeSurveyor} - ${perSurveyor.namaSurveyor}`}`,
            15,
            tempHeight
          );
          doc.text(`${tempKe3}`, 200, tempHeight);
          doc.text(`${tempKe6}`, 215, tempHeight);
          doc.text(`${tempKe9}`, 230, tempHeight);
          doc.text(`${tempKe12}`, 245, tempHeight);
          doc.text(`${tempKe15}`, 260, tempHeight);
          doc.text(`${tempKe18}`, 275, tempHeight);
          doc.text(`${tempKe21}`, 290, tempHeight);
          doc.text(`${tempKe24}`, 305, tempHeight);
          doc.text(`${tempKe27}`, 320, tempHeight);
          doc.text(`${tempKe30}`, 335, tempHeight);
          doc.text(`${tempKe33}`, 350, tempHeight);
          doc.text(`${tempKe36}`, 365, tempHeight);
          doc.text(`${tempP}`, 380, tempHeight);
          doc.text(`${tempTotal}`, 400, tempHeight);
        }
      }

      if (!ifNoPenerimaan) {
        tempHeight = 55;
      }
    }
    tempHeight += 4;
    doc.line(15, tempHeight, 405, tempHeight);
    tempHeight += 8;
    doc.text(`Total : `, 15, tempHeight);
    doc.text(`${tempTotalKe3}`, 200, tempHeight);
    doc.text(`${tempTotalKe6}`, 215, tempHeight);
    doc.text(`${tempTotalKe9}`, 230, tempHeight);
    doc.text(`${tempTotalKe12}`, 245, tempHeight);
    doc.text(`${tempTotalKe15}`, 260, tempHeight);
    doc.text(`${tempTotalKe18}`, 275, tempHeight);
    doc.text(`${tempTotalKe21}`, 290, tempHeight);
    doc.text(`${tempTotalKe24}`, 305, tempHeight);
    doc.text(`${tempTotalKe27}`, 320, tempHeight);
    doc.text(`${tempTotalKe30}`, 335, tempHeight);
    doc.text(`${tempTotalKe33}`, 350, tempHeight);
    doc.text(`${tempTotalKe36}`, 365, tempHeight);
    doc.text(`${tempTotalP}`, 380, tempHeight);
    doc.text(`${tempGrandTotal}`, 400, tempHeight);
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
    setPersenLoading("0");
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Point 3 Bulan
      </Typography>
      <Divider sx={dividerStyle} />
      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Jenis</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="SURVEYOR"
          value={jenisSurveyor}
          onChange={handleChangeLaporan}
        >
          <FormControlLabel
            value="SURVEYOR"
            control={<Radio />}
            label="Surveyor"
          />
          <FormControlLabel value="CMO" control={<Radio />} label="CMO" />
          <FormControlLabel value="SEMUA" control={<Radio />} label="Semua" />
        </RadioGroup>
      </FormControl>
      <hr />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputDariTgl}
                  onChange={(e) => setInputDariTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputDariTgl === null && true}
                      helperText={
                        error &&
                        inputDariTgl === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4" style={textRight}>
                Kode Surveyor :
              </Form.Label>
              <Col sm="8">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={surveyorOptions}
                  defaultValue={kodeSurveyor}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      error={error && kodeSurveyor.length === 0 && true}
                      helperText={
                        error &&
                        kodeSurveyor.length === 0 &&
                        "Kode Surveyor harus diisi!"
                      }
                      {...params}
                    />
                  )}
                  onInputChange={(e, value) =>
                    setKodeSurveyor(value.split(" ", 1)[0])
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            variant="contained"
            startIcon={<FormatListBulletedIcon />}
            onClick={() => downloadPdfRekap()}
          >
            REKAP
          </Button>
          <Button
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            RINCI
          </Button>
        </ButtonGroup>
      </Box>
      <table ref={tableRef} style={{ visibility: "hidden" }}>
        <tbody>
          <tr>
            <th>Laporan Tunggakan</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>No</th>
            <th>No. Jual</th>
            <th>Nama</th>
            <th>Alamat</th>
            <th>Telepon</th>
            <th>Tipe</th>
            <th>Angsuran</th>
            <th>Surveyor</th>
            <th>Tenor</th>
            <th>Sisa Bulan</th>
            <th>Jatuh Tempo</th>
            <th>MD1</th>
            <th>MD2</th>
            <th>MD3</th>
            <th>SP</th>
            <th>ST</th>
            <th>HR</th>
          </tr>
          {tunggakan.map((item, i) => (
            <tr>
              <th>{item.no}</th>
              <th>{item.noJual}</th>
              <th>{item.namaRegister}</th>
              <th>{item.almRegister}</th>
              <th>{item.tlpRegister}</th>
              <th>{item.tipe}</th>
              <th>{item.angPerBulan}</th>
              <th>{item.kodeSurveyor}</th>
              <th>{item.tenor}</th>
              <th>{item.sisaBulan}</th>
              <th>{item.tglJatuhTempo}</th>
              <th>{item.tglMd1}</th>
              <th>{item.tglMd2}</th>
              <th>{item.tglMd3}</th>
              <th>{item.tglSpTerakhir}</th>
              <th>{item.tglStTerakhir}</th>
              <th>{item.hr}</th>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default Point3Bulan;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
};
