import React, { useState, useContext } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Col, Row, Form } from "react-bootstrap";

const JemputanPerKolektor = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let date = new Date();
  let [inputDariTgl, setInputDariTgl] = useState(date);
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  let [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [persenLoading, setPersenLoading] = useState("0");
  const { screenSize } = useStateContext();

  const columnsRinci = [
    { title: "No.", field: "no" },
    { title: "No. Kwitansi", field: "noKwitansi" },
    { title: "Tanggal", field: "tglBayarFormatted" },
    { title: "Customer", field: "customer" },
    { title: "Jumlah", field: "jumlah" },
  ];

  const columnsRekap = [
    { title: "No", field: "no" },
    { title: "Kode", field: "kodeSurveyor" },
    { title: "Nama Kolektor", field: "namaSurveyor" },
    { title: "Jumlah", field: "totalTable" },
  ];

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const downloadPdfRinci = async () => {
    setLoading(true);
    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);

    let tempPersenLoading = parseInt(persenLoading);
    tempPersenLoading += 20;
    setPersenLoading(tempPersenLoading.toString());

    setTimeout(() => {
      tempPersenLoading += 20;
      setPersenLoading(tempPersenLoading.toString());
    }, "5000");

    const penerimaans = await axios.post(
      `${tempUrl}/penerimaansForLaporanJemputanRinci`,
      {
        dariTgl: inputDariTgl,
        sampaiTgl: inputSampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );

    const jemputanPerKolektor = groupBy(penerimaans.data, "surveyor");

    let tempTotal = 0;
    let tempSubTotal = 0;
    let tempLength = 0;
    let tempSubGroupHeight = 50;
    let tempHeight = 35;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatDate(date);
    const doc = new jsPDF("l", "mm", [230, 210]);
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Rincian Jemputan - Per Kolektor`, 80, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      200
    );
    doc.text(`Periode : ${user.periode.namaPeriode}`, 15, 35);
    doc.text(`Dari Tanggal : ${dariTgl} s/d : ${sampaiTgl}`, 15, 40);
    for (var i = 0; i < Object.keys(jemputanPerKolektor).length; i++) {
      doc.setFontSize(10);
      doc.text(
        `Kolektor : ${Object.values(jemputanPerKolektor)[i][0].surveyor}`,
        15,
        tempSubGroupHeight
      );
      doc.autoTable({
        styles: {
          fontSize: 8,
        },
        startY: tempSubGroupHeight + 5,
        columns: columnsRinci.map((col) => ({
          ...col,
          dataKey: col.field,
        })),
        body: Object.values(jemputanPerKolektor)[i].map((data, index) => ({
          ...data,
          no: index + 1,
        })),
        headStyles: {
          fillColor: [117, 117, 117],
          color: [0, 0, 0],
        },
        didDrawPage: (d) => {
          tempSubGroupHeight = d.cursor.y + 15;
          tempHeight = d.cursor.y + 10;
        },
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 30 },
          2: { cellWidth: 30 },
          3: { cellWidth: 100 },
          4: { cellWidth: 30 },
          // etc
        },
      });
      Object.values(jemputanPerKolektor)[i].map((val) => {
        tempSubTotal += val.jemputan;
        tempTotal += val.jemputan;
      });
      tempLength += Object.values(jemputanPerKolektor)[i].length;
      doc.setFontSize(8);
      doc.text(
        `Sub Total : ${
          Object.values(jemputanPerKolektor)[i].length
        } | Rp ${tempSubTotal.toLocaleString("en-US")}`,
        196,
        tempHeight,
        {
          align: "right",
        }
      );
      tempSubTotal = 0;
    }
    doc.setFontSize(10);
    doc.text(
      `Total : ${tempLength} | Rp ${tempTotal.toLocaleString("en-US")}`,
      196,
      tempHeight + 8,
      {
        align: "right",
      }
    );
    window.open(URL.createObjectURL(doc.output("blob")));
    setPersenLoading("0");
    setLoading(false);
  };

  const downloadPdfRekap = async () => {
    setLoading(true);
    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);

    let tempPersenLoading = parseInt(persenLoading);
    tempPersenLoading += 20;
    setPersenLoading(tempPersenLoading.toString());

    const response = await axios.post(
      `${tempUrl}/penerimaansForLaporanJemputanRekap`,
      {
        dariTgl: inputDariTgl,
        sampaiTgl: inputSampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: user.cabang._id,
      }
    );

    let tempTotal = 0;
    let tempHeight = 50;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Rekap Jemputan - Per Kolektor`, 60, 30);
    doc.setFontSize(10);
    doc.text(`Dari Tanggal : ${dariTgl} s/d ${sampaiTgl}`, 15, 40);
    doc.text(`Cabang : ${user.cabang._id}`, 15, 45);
    doc.setFontSize(12);
    function alignCol(data) {
      var col = data.column.index;
      if (col >= 3 && col <= 4) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 50,
      columns: columnsRekap.map((col) => ({ ...col, dataKey: col.field })),
      body: response.data,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didDrawPage: (d) => {
        tempHeight = d.cursor.y;
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 50 },
        2: { cellWidth: 100 },
        3: { cellWidth: 20 },
        // etc
      },
    });
    response.data.map((val) => {
      tempTotal += val.total;
    });
    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(10);
    tempHeight += 2;
    doc.line(15, tempHeight, 195, tempHeight);
    tempHeight += 6;
    doc.text(`TOTAL : `, 15, tempHeight);
    doc.text(`${tempTotal.toLocaleString("en-US")}`, 192, tempHeight, {
      align: "right",
    });
    tempHeight += 4;
    doc.line(15, tempHeight, 195, tempHeight);
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader persenLoading={persenLoading} />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Jemputan - Per Kolektor
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputDariTgl}
                  onChange={(e) => setInputDariTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputDariTgl === null && true}
                      helperText={
                        error &&
                        inputDariTgl === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <ButtonGroup variant="contained">
          <Button
            color="primary"
            startIcon={<PrintIcon />}
            onClick={() => {
              if (inputSampaiTgl === null || inputDariTgl === null) {
                setError(true);
              } else downloadPdfRekap();
            }}
          >
            REKAP
          </Button>
          <Button
            color="secondary"
            startIcon={<PrintIcon />}
            onClick={() => {
              if (inputSampaiTgl === null || inputDariTgl === null) {
                setError(true);
              } else downloadPdfRinci();
            }}
          >
            RINCI
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default JemputanPerKolektor;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};
