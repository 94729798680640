import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import { ProSidebarProvider } from "react-pro-sidebar";
import { ContextProvider } from "./contexts/ContextProvider";
import { AuthContextProvider } from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import firebase from "firebase/compat/app";

import "bootstrap/dist/css/bootstrap.min.css";

const firebaseConfig = {
  apiKey: "AIzaSyBHaMf-cg6h7RvpBTxsGTF228kR_sJ2NMo",
  authDomain: "sjmgroupjakarta.firebaseapp.com",
  projectId: "sjmgroupjakarta",
  storageBucket: "sjmgroupjakarta.appspot.com",
  messagingSenderId: "206898401425",
  appId: "1:206898401425:web:dbae9e7b6db21d2368af30"
};

firebase.initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <ContextProvider>
          <App />
        </ContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();
