import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../../constants/helper";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { ShowTableKelurahan } from "../../../../components/ShowTable";
import { FetchErrorHandling } from "../../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { Col, Row, Form } from "react-bootstrap";

const TampilKelurahan = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const [isFetchError, setIsFetchError] = useState(false);
  const [isKelurahanExist, setIsKelurahanExist] = useState(false);
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [namaKecamatan, setNamaKecamatan] = useState("");
  const [kodeKelurahan, setKodeKelurahan] = useState("");
  const [namaKelurahan, setNamaKelurahan] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [kelurahansData, setKelurahansData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { title: "No", field: "no" },
    { title: "Kode Kecamatan", field: "kodeKecamatan" },
    { title: "Nama Kecamatan", field: "namaKecamatan" },
    { title: "Kode Kelurahan", field: "kodeKelurahan" },
    { title: "Nama Kelurahan", field: "namaKelurahan" },
  ];

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id ? getKelurahanById() : setIsKelurahanExist(false);
  }, [id]);

  useEffect(() => {
    getKelurahansData();
  }, [page, searchTerm]);

  const getKelurahansData = async () => {
    setLoading(true);
    try {
      const allKelurahans = await axios.post(
        `${tempUrl}/kelurahansPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          id: user._id,
          token: user.token,
        }
      );
      setQuery(searchTerm);
      setKelurahansData(allKelurahans.data.kelurahans);
      setPage(allKelurahans.data.page);
      setPages(allKelurahans.data.totalPage);
      setRows(allKelurahans.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getKelurahansForDoc = async (isPdf, isExcel) => {
    setLoading(true);
    const allKelurahansForDoc = await axios.post(
      `${tempUrl}/kelurahansForDoc`,
      {
        id: user._id,
        token: user.token,
      }
    );
    setLoading(false);
    if (isPdf) {
      downloadPdf(allKelurahansForDoc.data);
    } else if (isExcel) {
      downloadExcel(allKelurahansForDoc.data);
    }
  };

  const getKelurahanById = async () => {
    setLoading(true);
    if (id) {
      const pickedKelurahan = await axios.post(`${tempUrl}/kelurahans/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsKelurahanExist(true);
      setKodeKecamatan(pickedKelurahan.data.kodeKecamatan);
      setNamaKecamatan(pickedKelurahan.data.namaKecamatan);
      setKodeKelurahan(pickedKelurahan.data.kodeKelurahan);
      setNamaKelurahan(pickedKelurahan.data.namaKelurahan);
    }
    setLoading(false);
  };

  const deleteKelurahan = async (id) => {
    const findRegisterKelurahan = await axios.post(
      `${tempUrl}/registersKelurahan`,
      {
        kelurahanId: id,
        id: user._id,
        token: user.token,
      }
    );
    const findJualKelurahan = await axios.post(`${tempUrl}/jualsKelurahan`, {
      kodeKelurahan: id,
      id: user._id,
      token: user.token,
    });
    let isKelurahanDataExist =
      findRegisterKelurahan.data.length > 0 ||
      findJualKelurahan.data.length > 0;
    if (isKelurahanDataExist) {
      // There's Record -> Forbid Delete
      handleClickOpen();
    } else {
      // No Record Found -> Delete
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/deleteKelurahan/${id}`, {
          id: user._id,
          token: user.token,
        });
        setKodeKecamatan("");
        setNamaKecamatan("");
        setKodeKelurahan("");
        setNamaKelurahan("");
        setLoading(false);
        navigate("/kelurahan");
        getKelurahansData();
      } catch (error) {
        if (error.response.status == 401) {
          dispatch({ type: "LOGOUT" });
          navigate("/");
        }
        console.log(error);
      }
    }
  };

  const downloadPdf = (data) => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Kelurahan`, 90, 30);
    doc.setFontSize(10);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    let no = 0;
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data.map((col) => {
        no++;
        return { ...col, no };
      }),
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadExcel = (data) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Kelurahan`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarKelurahan.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Master</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Kelurahan
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Tidak bisa dihapus karena sudah ada data!`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Kode Kelurahan data: ${kodeKelurahan}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            startIcon={<PrintIcon />}
            onClick={() => getKelurahansForDoc(true, false)}
          >
            CETAK
          </Button>
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => getKelurahansForDoc(false, true)}
          >
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={isKelurahanExist}
          addLink={`/kelurahan/tambahKelurahan`}
          editLink={`/kelurahan/${id}/edit`}
          deleteUser={deleteKelurahan}
          nameUser={kodeKelurahan}
        />
      </Box>
      <Divider sx={dividerStyle} />
      {isKelurahanExist && (
        <>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Kelurahan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeKelurahan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Kelurahan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaKelurahan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Kode Kecamatan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeKecamatan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama Kecamatan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaKecamatan} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableKelurahan currentPosts={kelurahansData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilKelurahan;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw",
  // },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};
