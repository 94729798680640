import React, { useState, useContext, useRef } from "react";
import "../../../constants/report.css";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Container, Card, Form, Row, Col, Table } from "react-bootstrap";
import jsPDF from "jspdf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PrintIcon from "@mui/icons-material/Print";

const LaporanJurnalUmum = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const reportTemplateRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  let nowDate = new Date();
  let [dariTgl, setDariTgl] = useState(new Date(user.periode.periodeAwal));
  let [sampaiTgl, setSampaiTgl] = useState(new Date(user.periode.periodeAkhir));
  const [value, setValue] = useState("perAccount");

  const [jurnalUmumsData, setJurnalUmumsData] = useState([]);
  const [isFetchError, setIsFetchError] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewPdfNoBukti, setPreviewPdfNoBukti] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeLaporan = (event) => {
    setValue(event.target.value);
    setPreviewPdf(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        if (value === "perAccount") {
          window.open(URL.createObjectURL(doc.output("blob")));
        } else {
          window.open(URL.createObjectURL(doc.output("blob")));
        }
      },
      html2canvas: { scale: 0.44 },
    });
  };

  const tampilPdfPerAccount = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setJurnalUmumsData([]);
      if (value === "perAccount") {
        setPreviewPdfNoBukti(false);
        const jurnalUmums = await axios.post(
          `${tempUrl}/jurnalUmumLaporanPerAccount`,
          {
            dariTgl,
            sampaiTgl,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        setJurnalUmumsData(jurnalUmums.data);
        setPreviewPdf(!previewPdf);
      } else {
        setPreviewPdf(false);
        const jurnalUmums = await axios.post(
          `${tempUrl}/jurnalUmumLaporanPerNoBukti`,
          {
            dariTgl,
            sampaiTgl,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        setJurnalUmumsData(jurnalUmums.data);
        setPreviewPdfNoBukti(!previewPdfNoBukti);
      }
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textTableRight = {
    letterSpacing: "0.01px",
    textAlign: "right",
  };

  const tableText = {
    letterSpacing: "0.01px",
  };

  const tableLastText = {
    borderTop: "1px solid black",
    letterSpacing: "0.01px",
  };

  const list = [];

  let keys = Object.keys(jurnalUmumsData);
  let tempDebet = 0;
  let totalDebet = 0;
  let tempKredit = 0;
  let totalKredit = 0;
  let tempSaldo = 0;
  let totalSaldo = 0;

  if (previewPdf) {
    for (let j = 0; j < Object.keys(jurnalUmumsData).length; j++) {
      list.push(
        <tr style={tableText}>
          <td style={{ fontWeight: 700 }} colspan="6">
            Account: {jurnalUmumsData[keys[j]][0].kodeCOA} -{" "}
            {jurnalUmumsData[keys[j]][0].namaCOA}
          </td>
        </tr>
      );
      for (let i = 0; i < jurnalUmumsData[keys[j]].length; i++) {
        let tempTglJurnal = new Date(jurnalUmumsData[keys[j]][i].tglJurnal);
        if (jurnalUmumsData[keys[j]][i].jenis === "D") {
          tempDebet += jurnalUmumsData[keys[j]][i].jumlah;
          totalDebet += jurnalUmumsData[keys[j]][i].jumlah;
          tempSaldo += jurnalUmumsData[keys[j]][i].jumlah;
          totalSaldo += jurnalUmumsData[keys[j]][i].jumlah;
        } else {
          tempKredit += jurnalUmumsData[keys[j]][i].jumlah;
          totalKredit += jurnalUmumsData[keys[j]][i].jumlah;
          tempSaldo -= jurnalUmumsData[keys[j]][i].jumlah;
          totalSaldo -= jurnalUmumsData[keys[j]][i].jumlah;
        }
        list.push(
          <tr style={tableText}>
            <td>{`${tempTglJurnal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(tempTglJurnal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${tempTglJurnal.getFullYear()}`}</td>
            <td>{jurnalUmumsData[keys[j]][i].noBukti}</td>
            <td>{jurnalUmumsData[keys[j]][i].keterangan.slice(0, 35)}</td>
            {jurnalUmumsData[keys[j]][i].jenis === "D" ? (
              <>
                <td style={textTableRight}>
                  {jurnalUmumsData[keys[j]][i].jumlah.toLocaleString("en-US")}
                </td>
                <td></td>
              </>
            ) : (
              <>
                <td></td>
                <td style={textTableRight}>
                  {jurnalUmumsData[keys[j]][i].jumlah.toLocaleString("en-US")}
                </td>
              </>
            )}
            <td style={textTableRight}>{tempSaldo.toLocaleString("en-US")}</td>
          </tr>
        );

        list.push(
          <tr style={tableLastText}>
            <td style={{ fontWeight: 700 }} colspan="3">
              SubTotal Account: {jurnalUmumsData[keys[j]][0].kodeCOA} -{" "}
              {jurnalUmumsData[keys[j]][0].namaCOA}
            </td>
            <td style={textTableRight}>{tempDebet.toLocaleString("en-US")}</td>
            <td style={textTableRight}>{tempKredit.toLocaleString("en-US")}</td>
            <td style={textTableRight}>{tempSaldo.toLocaleString("en-US")}</td>
          </tr>
        );
        tempDebet = 0;
        tempKredit = 0;
        tempSaldo = 0;
      }
    }

    list.push(
      <tr style={{ borderTop: "1px solid black" }}>
        <td style={{ fontWeight: 700 }} colspan="3">
          Balance:
        </td>
        <td style={textTableRight}>{totalDebet.toLocaleString("en-US")}</td>
        <td style={textTableRight}>{totalKredit.toLocaleString("en-US")}</td>
        <td style={textTableRight}>{totalSaldo.toLocaleString("en-US")}</td>
      </tr>
    );
  }

  if (previewPdfNoBukti) {
    for (let j = 0; j < Object.keys(jurnalUmumsData).length; j++) {
      list.push(
        <tr style={tableText}>
          <td style={{ fontWeight: 700 }} colspan="6">
            No. Bukti: {jurnalUmumsData[keys[j]][0].noBukti}
          </td>
        </tr>
      );
      for (let i = 0; i < jurnalUmumsData[keys[j]].length; i++) {
        let tempTglJurnal = new Date(jurnalUmumsData[keys[j]][i].tglJurnal);
        if (jurnalUmumsData[keys[j]][i].jenis === "D") {
          tempDebet += jurnalUmumsData[keys[j]][i].jumlah;
          totalDebet += jurnalUmumsData[keys[j]][i].jumlah;
          tempSaldo += jurnalUmumsData[keys[j]][i].jumlah;
          totalSaldo += jurnalUmumsData[keys[j]][i].jumlah;
        } else {
          tempKredit += jurnalUmumsData[keys[j]][i].jumlah;
          totalKredit += jurnalUmumsData[keys[j]][i].jumlah;
          tempSaldo -= jurnalUmumsData[keys[j]][i].jumlah;
          totalSaldo -= jurnalUmumsData[keys[j]][i].jumlah;
        }
        list.push(
          <tr style={tableText}>
            <td>{`${tempTglJurnal.getDate().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${(tempTglJurnal.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}-${tempTglJurnal.getFullYear()}`}</td>
            <td>{jurnalUmumsData[keys[j]][i].noBukti}</td>
            <td>{jurnalUmumsData[keys[j]][i].keterangan.slice(0, 35)}</td>
            {jurnalUmumsData[keys[j]][i].jenis === "D" ? (
              <>
                <td style={textTableRight}>
                  {jurnalUmumsData[keys[j]][i].jumlah.toLocaleString("en-US")}
                </td>
                <td></td>
              </>
            ) : (
              <>
                <td></td>
                <td style={textTableRight}>
                  {jurnalUmumsData[keys[j]][i].jumlah.toLocaleString("en-US")}
                </td>
              </>
            )}
            <td style={textTableRight}>{tempSaldo.toLocaleString("en-US")}</td>
          </tr>
        );
      }
      list.push(
        <tr style={tableLastText}>
          <td style={{ fontWeight: 700 }} colspan="3">
            SubTotal No. Bukti: {jurnalUmumsData[keys[j]][0].noBukti}
          </td>
          <td style={textTableRight}>{tempDebet.toLocaleString("en-US")}</td>
          <td style={textTableRight}>{tempKredit.toLocaleString("en-US")}</td>
          <td style={textTableRight}>{tempSaldo.toLocaleString("en-US")}</td>
        </tr>
      );
      tempDebet = 0;
      tempKredit = 0;
      tempSaldo = 0;
    }

    list.push(
      <tr style={{ borderTop: "1px solid black" }}>
        <td style={{ fontWeight: 700 }} colspan="3">
          Balance:
        </td>
        <td style={textTableRight}>{totalDebet.toLocaleString("en-US")}</td>
        <td style={textTableRight}>{totalKredit.toLocaleString("en-US")}</td>
        <td style={textTableRight}>{totalSaldo.toLocaleString("en-US")}</td>
      </tr>
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Accounting</h3>
      <h5 style={{ fontWeight: 400 }}>Laporan Jurnal Umum</h5>
      <Typography sx={subTitleText}>
        Periode : {user.periode.namaPeriode}
      </Typography>
      <hr />

      <FormControl sx={{ marginTop: 1 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">Laporan</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="perAccount"
          value={value}
          onChange={handleChangeLaporan}
        >
          <FormControlLabel
            value="perAccount"
            control={<Radio />}
            label="Per Account"
          />
          <FormControlLabel
            value="perNoBukti"
            control={<Radio />}
            label="Per No Bukti"
          />
        </RadioGroup>
      </FormControl>

      <Card>
        <Card.Header>Rentang Tanggal</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={tampilPdfPerAccount}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Dari :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={dariTgl}
                      minDate={new Date(user.periode.periodeAwal)}
                      maxDate={new Date(user.periode.periodeAkhir)}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setDariTgl(date);
                        setPreviewPdf(false);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Sampai :
                  </Form.Label>
                  <Col sm="8">
                    <DatePicker
                      required
                      dateFormat="dd/MM/yyyy"
                      selected={sampaiTgl}
                      minDate={new Date(user.periode.periodeAwal)}
                      maxDate={new Date(user.periode.periodeAkhir)}
                      customInput={<Form.Control required />}
                      onChange={(date) => {
                        setSampaiTgl(date);
                        setPreviewPdf(false);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="contained"
                startIcon={<PrintIcon />}
                type="submit"
              >
                TAMPIL PDF
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>

      {previewPdf && (
        <div style={{ marginTop: "10px" }}>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} id="content" style={pdfContainer}>
            <p>
              {setting.namaPerusahaan} - {setting.lokasiPerusahaan}
            </p>
            <p>({setting.lokasiSP})</p>
            <p>{setting.kotaPerusahaan}</p>
            <p>NO. TELP. {setting.noTelpPerusahaan}</p>
            <p>
              Dicetak Oleh: {user.username} | Tanggal:
              {` ${nowDate.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(nowDate.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${nowDate.getFullYear()} `}{" "}
              | Jam:{" "}
              {` ${nowDate.getHours().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${nowDate.getSeconds()} `}
            </p>
            <h5 style={{ textAlign: "center", fontWeight: "700" }}>
              Laporan Jurnal Umum Per Account
            </h5>
            <p>
              Dari Tanggal :
              {` ${dariTgl.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(dariTgl.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${dariTgl.getFullYear()} `}
              S/D :
              {` ${sampaiTgl.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(sampaiTgl.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${sampaiTgl.getFullYear()}`}
            </p>
            <table class="styled-table">
              <thead>
                <tr>
                  <th style={tableTitle}>Tgl.</th>
                  <th style={tableTitle}>No. Bukti</th>
                  <th style={tableTitle}>Keterangan Jurnal</th>
                  <th style={tableTitleRight}>Debet</th>
                  <th style={tableTitleRight}>Kredit</th>
                  <th style={tableTitleRight}>Saldo</th>
                </tr>
              </thead>
              <tbody>{list}</tbody>
            </table>
          </div>
        </div>
      )}

      {previewPdfNoBukti && (
        <div style={{ marginTop: "10px" }}>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePdf}
          >
            CETAK
          </Button>
          <div ref={reportTemplateRef} id="content" style={pdfContainer}>
            <p>
              {setting.namaPerusahaan} - {setting.lokasiPerusahaan}
            </p>
            <p>({setting.lokasiSP})</p>
            <p>{setting.kotaPerusahaan}</p>
            <p>NO. TELP. {setting.noTelpPerusahaan}</p>
            <p>
              Dicetak Oleh: {user.username} | Tanggal:
              {` ${nowDate.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(nowDate.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${nowDate.getFullYear()} `}{" "}
              | Jam:{" "}
              {` ${nowDate.getHours().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(nowDate.getMinutes() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${nowDate.getSeconds()} `}
            </p>
            <h5 style={{ textAlign: "center", fontWeight: "700" }}>
              Laporan Jurnal Umum Per No. Bukti
            </h5>
            <p>
              Dari Tanggal :
              {` ${dariTgl.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(dariTgl.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${dariTgl.getFullYear()} `}
              S/D :
              {` ${sampaiTgl.getDate().toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${(sampaiTgl.getMonth() + 1).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}-${sampaiTgl.getFullYear()}`}
            </p>
            <table class="styled-table">
              <thead>
                <tr>
                  <th>Tgl.</th>
                  <th>No. Bukti</th>
                  <th>Keterangan Jurnal</th>
                  <th>Debet</th>
                  <th>Kredit</th>
                  <th>Saldo</th>
                </tr>
              </thead>
              <tbody>{list}</tbody>
            </table>
          </div>
        </div>
      )}

      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default LaporanJurnalUmum;

const subTitleText = {
  fontWeight: "900",
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const tableTitle = {
  padding: "10px",
};

const tableTitleRight = {
  padding: "10px",
  textAlign: "right",
};

const pdfContainer = {
  padding: "20px",
  letterSpacing: "0.01px",
  fontSize: "12px",
  fontFamily: "sans-serif",
  width: "1000px",
};
