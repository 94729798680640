import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { tempUrl } from "../../../contexts/ContextProvider";
import { useStateContext } from "../../../contexts/ContextProvider";
import { AuthContext } from "../../../contexts/AuthContext";
import { ShowTablePointKembali } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import {
  SearchBar,
  Loader,
  ButtonModifier,
  ScrollToTop,
} from "../../../components";
import { Box, Typography, Divider, Pagination, Button } from "@mui/material";
import { Col, Row, Form } from "react-bootstrap";

const TampilPointKembali = () => {
  const { setting, user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  const [isPointKembalisExist, setIsStsExist] = useState(false);
  const [noPointKembali, setNoPointKembali] = useState("");
  const [tglKetemu, setTglKetemu] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [noJual, setNoJual] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [noRangka, setNoRangka] = useState("");

  const [almRegister, setAlmRegister] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [nopol, setNopol] = useState("");
  const [kodeTipe, setKodeTipe] = useState("");

  const [value, setValue] = useState("Semua");
  const [searchTerm, setSearchTerm] = useState("");
  const [pointKembalisData, setPointKembalisData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    id ? getPointKembaliById() : setIsStsExist(false);
  }, [id]);

  useEffect(() => {
    if (user.tipeUser === "OWN/DIREKSI") {
      getPointKembaliAllCabangsData();
    } else {
      getPointKembalisData();
    }
  }, [value, page, searchTerm]);

  const getPointKembaliAllCabangsData = async () => {
    setLoading(true);
    try {
      let allSts = await axios.post(
        `${tempUrl}/pointKembaliAllCabangsPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          id: user._id,
          token: user.token,
        }
      );

      setQuery(searchTerm);
      setPointKembalisData(allSts.data.pointKembalis);
      setPage(allSts.data.page);
      setPages(allSts.data.totalPage);
      setRows(allSts.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getPointKembalisData = async () => {
    setLoading(true);
    try {
      let allSts = await axios.post(
        `${tempUrl}/pointKembalisPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          id: user._id,
          token: user.token,
          kodeCabang: user.cabang._id,
        }
      );

      setQuery(searchTerm);
      setPointKembalisData(allSts.data.pointKembalis);
      setPage(allSts.data.page);
      setPages(allSts.data.totalPage);
      setRows(allSts.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getPointKembaliById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/pointKembalis/${id}`, {
        id: user._id,
        token: user.token,
      });
      setIsStsExist(true);
      setNoPointKembali(response.data.noPointKembali);
      setTglKetemu(formatDate(response.data.tglKetemu));
      setKeterangan(response.data.keterangan);
      setNoJual(response.data.noJual);
      setNamaRegister(response.data.idJual.namaRegister);
      setTahun(response.data.idJual.tahun);
      setNamaWarna(response.data.idJual.namaWarna);
      setNoRangka(response.data.idJual.noRangka);

      setAlmRegister(response.data.idJual.almRegister);
      setTlpRegister(response.data.idJual.tlpRegister);
      setNopol(response.data.idJual.nopol);
      setKodeTipe(response.data.idJual.tipe);
    }
  };

  const deletePointKembali = async (id) => {
    try {
      setLoading(true);

      // Delete Point Kembali
      await axios.post(`${tempUrl}/deletePointKembali/${id}`, {
        id: user._id,
        token: user.token,
      });
      setNoPointKembali("");
      setTglKetemu("");
      setKeterangan("");
      setNoJual("");
      setLoading(false);
      navigate("/pointKembali");
      getPointKembalisData();
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <ScrollToTop />
      <Typography color="#757575">Piutang</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Point Kembali
      </Typography>
      <Box sx={buttonModifierContainer}>
        {user.tipeUser !== "OWN/DIREKSI" && (
          <ButtonModifier
            id={id}
            kode={isPointKembalisExist}
            addLink={`/pointKembali/tambahPointKembali`}
            editLink={`/pointKembali/${id}/edit`}
            deleteUser={deletePointKembali}
            nameUser={noJual}
          />
        )}
      </Box>
      <hr />
      {isPointKembalisExist && (
        <>
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA POINT KEMBALI
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Point Kembali :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noPointKembali} disabled readOnly />
                      <Form.Control.Feedback type="invalid">
                        No. Point Kembali harus diisi!
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tgl. Ketemu :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tglKetemu} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Jual :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noJual} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Telepon :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={tlpRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nama :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={namaRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Alamat :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={almRegister} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Keterangan :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={keterangan}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
          <Typography variant="h5" sx={titleStyle} color="primary">
            DATA MOTOR
          </Typography>
          <Box sx={showDataContainer}>
            <Box sx={showDataWrapper}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tipe :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={kodeTipe} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      No. Rangka :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={noRangka} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
            <Box sx={[showDataWrapper, secondWrapper]}>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Tahun / Warna :
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control value={tahun} disabled />
                    </Col>
                    <Col sm={4}>
                      <Form.Control value={namaWarna} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4" style={textRight}>
                      Nopol :
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control value={nopol} disabled />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Box>
          </Box>
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTablePointKembali
          currentPosts={pointKembalisData}
          tipeUser={user.tipeUser}
        />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilPointKembali;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};
