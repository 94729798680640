import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { formatDate, formatTime } from "../../../../constants/helper";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../../contexts/ContextProvider";
import { Loader } from "../../../../components";
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  ButtonGroup,
  Autocomplete,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Col, Row, Form } from "react-bootstrap";

const CariLapPenjualanPerSurveyor = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  let date = new Date();
  const [cabangInput, setCabangInput] = useState("");
  let [inputDariTgl, setInputDariTgl] = useState(date);
  let [inputSampaiTgl, setInputSampaiTgl] = useState(date);
  const [allCabang, setAllCabang] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { screenSize } = useStateContext();

  const columns = [
    { title: "No", field: "no" },
    { title: "Kode", field: "kodeSurveyor" },
    { title: "Nama Surveyor", field: "namaSurveyor" },
    { title: "Jumlah", field: "total" },
    { title: "%", field: "persen" },
  ];

  const cabangOptions = allCabang.map((user) => ({
    label: `${user._id} - ${user.namaCabang}`,
  }));

  useEffect(() => {
    if (user.tipeUser === "OWN/DIREKSI") {
      getAllCabang();
    } else {
      setCabangInput(user.cabang._id);
    }
  }, []);

  const getAllCabang = async () => {
    setLoading(true);
    const allCabang = await axios.post(`${tempUrl}/cabangs`, {
      id: user._id,
      token: user.token,
    });
    setAllCabang(allCabang.data);
    setLoading(false);
  };

  const downloadPdfRekap = async () => {
    setLoading(true);
    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);
    const response = await axios.post(
      `${tempUrl}/jualsForLaporanPenjualanBySurveyorRekap`,
      {
        dariTgl: inputDariTgl,
        sampaiTgl: inputSampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: cabangInput,
      }
    );

    let tempTotal = 0;
    let tempHeight = 50;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Penjualan Per Surveyor Rekap`, 60, 30);
    doc.setFontSize(10);
    doc.text(`Dari Tanggal : ${dariTgl} s/d ${sampaiTgl}`, 15, 40);
    doc.text(
      `Cabang : ${cabangInput === "" ? "SEMUA CABANG" : cabangInput}`,
      15,
      45
    );
    doc.setFontSize(12);
    function alignCol(data) {
      var col = data.column.index;
      if (col >= 3 && col <= 4) {
        data.cell.styles.halign = "right";
      }
    }
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 50,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: response.data,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      didDrawPage: (d) => {
        tempHeight = d.cursor.y;
      },
      didParseCell: function (cell, data) {
        alignCol(cell, data);
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 50 },
        2: { cellWidth: 80 },
        3: { cellWidth: 20 },
        4: { cellWidth: 20 },
        // etc
      },
    });
    response.data.map((val) => {
      tempTotal += val.total;
    });
    doc.setDrawColor(0, 0, 0);
    doc.setFontSize(10);
    tempHeight += 2;
    doc.line(15, tempHeight, 195, tempHeight);
    tempHeight += 6;
    doc.text(`TOTAL : `, 15, tempHeight);
    doc.text(`${tempTotal}`, 172, tempHeight, {
      align: "right",
    });
    doc.text(`100`, 192, tempHeight, {
      align: "right",
    });
    tempHeight += 4;
    doc.line(15, tempHeight, 195, tempHeight);
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadPdfRinci = async () => {
    setLoading(true);
    let dariTgl = formatDate(inputDariTgl);
    let sampaiTgl = formatDate(inputSampaiTgl);
    const response = await axios.post(
      `${tempUrl}/jualsForLaporanPenjualanBySurveyorRinci`,
      {
        dariTgl: inputDariTgl,
        sampaiTgl: inputSampaiTgl,
        id: user._id,
        token: user.token,
        kodeCabang: cabangInput,
      }
    );

    let hal = 1;
    let y = 55;
    let total = 0;
    let totalValue = 0;
    let total0 = 0;
    let total6 = 0;
    let total9 = 0;
    let total12 = 0;
    let total15 = 0;
    let total18 = 0;
    let total21 = 0;
    let total24 = 0;
    let total30 = 0;
    let yCount = 0;
    let yTenor = 0;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF("l", "mm", [300, 210]);
    doc.setFontSize(12);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      200
    );
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`Hal: ${hal}`, 270, 5);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Laporan Penjualan Per Surveyor Rinci`, 110, 30);
    doc.setFontSize(10);
    doc.text(`Dari Tanggal : ${dariTgl} s/d ${sampaiTgl}`, 15, 40);
    doc.text(
      `Cabang : ${cabangInput === "" ? "SEMUA CABANG" : cabangInput}`,
      15,
      45
    );
    doc.line(15, y, 280, y);
    y += 5;
    doc.text(`Surveyor`, 38, y + 8);
    doc.text(`Tenor (Bulan)`, 120, y);
    doc.text(`Total`, 206, y + 8);
    doc.text(`Value`, 250, y + 8);
    y += 10;
    doc.line(15, y, 280, y);
    yTenor = y;
    yCount = y;
    for (let i = 0; i < response.data.length; i++) {
      y += 8;
      totalValue += response.data[i].hargaTunai;
      total += response.data[i].total;
      doc.text(
        `${response.data[i].kodeSurveyor} - ${response.data[
          i
        ].namaSurveyor.slice(0, 20)}`,
        20,
        y - 2
      );
      doc.text(`${response.data[i].total}`, 210, y - 2);
      doc.text(`${response.data[i].hargaTunaiDoc}`, 270, y - 2, {
        align: "right",
      });
      doc.line(15, y, 280, y);

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "0") {
          total0 += parseInt(value);
          doc.text(`${value}`, 85.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "6") {
          total6 += parseInt(value);
          doc.text(`${value}`, 97.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "9") {
          total9 += parseInt(value);
          doc.text(`${value}`, 110.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "12") {
          total12 += parseInt(value);
          doc.text(`${value}`, 122.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "15") {
          total15 += parseInt(value);
          doc.text(`${value}`, 135.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "18") {
          total18 += parseInt(value);
          doc.text(`${value}`, 147.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "21") {
          total21 += parseInt(value);
          doc.text(`${value}`, 160.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "24") {
          total24 += parseInt(value);
          doc.text(`${value}`, 171.5, y - 2);
        }
      }

      for (let name in response.data[i].allTenor) {
        let value = response.data[i].allTenor[name];
        if (name === "30") {
          total30 += parseInt(value);
          doc.text(`${value}`, 184.5, y - 2);
        }
      }

      if (y > 180) {
        doc.line(15, 55, 15, y);
        doc.line(80, 55, 80, y);
        doc.line(80, yTenor - 7, 193, yTenor - 7); // 1
        doc.text(`0`, 85.5, yTenor - 2);
        doc.line(92.5, yTenor - 7, 92.5, y); // 2
        doc.text(`6`, 97.5, yTenor - 2);
        doc.line(105, yTenor - 7, 105, y); // 3
        doc.text(`9`, 110.5, yTenor - 2);
        doc.line(117.5, yTenor - 7, 117.5, y); // 4
        doc.text(`12`, 122.5, yTenor - 2);
        doc.line(130, yTenor - 7, 130, y); // 5
        doc.text(`15`, 135.5, yTenor - 2);
        doc.line(142.5, yTenor - 7, 142.5, y); // 6
        doc.text(`18`, 147.5, yTenor - 2);
        doc.line(155, yTenor - 7, 155, y); // 7
        doc.text(`21`, 160.5, yTenor - 2);
        doc.line(167.5, yTenor - 7, 167.5, y); // 8
        doc.text(`24`, 171.5, yTenor - 2);
        doc.line(180, yTenor - 7, 180, y); // 9
        doc.text(`30`, 184.5, yTenor - 2);
        doc.line(192.5, 55, 192.5, y); // 10
        doc.line(230, 55, 230, y);
        doc.line(280, 55, 280, y);
        doc.line(15, y, 280, y);
        doc.addPage();
        doc.text(
          `${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`,
          15,
          10
        );
        doc.text(`Hal: ${hal}`, 270, 5);
        doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
        doc.setFontSize(16);
        doc.text(`Laporan Penjualan Per Surveyor Rinci`, 110, 30);
        doc.setFontSize(10);
        doc.text(`Dari Tanggal : ${dariTgl} s/d ${sampaiTgl}`, 15, 40);
        doc.text(`Periode : `, 15, 45);
        y = 55;
        doc.line(15, y, 280, y);
        y += 5;
        doc.text(`Surveyor`, 38, y + 8);
        doc.text(`Tenor (Bulan)`, 120, y);
        doc.text(`Total`, 206, y + 8);
        doc.text(`Value`, 250, y + 8);
        y += 10;
        doc.line(15, y, 280, y);
        hal++;
        doc.text(`Hal: ${hal}`, 370, 5);
        doc.text(
          `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
          10,
          200
        );
      }
    }

    y += 8;
    doc.setFont(undefined, "bold");
    doc.text(`TOTAL : `, 18, y - 2);
    doc.setFont(undefined, "normal");
    doc.text(`${total}`, 210, y - 2);
    doc.text(`${totalValue.toLocaleString("en-US")}`, 270, y - 2, {
      align: "right",
    });
    // Vertical Line
    doc.line(15, 55, 15, y);
    doc.line(80, 55, 80, y);
    doc.line(80, yTenor - 7, 193, yTenor - 7); // 1
    doc.text(`0`, 85.5, yTenor - 2);
    doc.text(`${total0}`, 85.5, y - 2);
    doc.line(92.5, yTenor - 7, 92.5, y); // 2
    doc.text(`6`, 97.5, yTenor - 2);
    doc.text(`${total6}`, 97.5, y - 2);
    doc.line(105, yTenor - 7, 105, y); // 3
    doc.text(`9`, 110.5, yTenor - 2);
    doc.text(`${total9}`, 110.5, y - 2);
    doc.line(117.5, yTenor - 7, 117.5, y); // 4
    doc.text(`12`, 122.5, yTenor - 2);
    doc.text(`${total12}`, 122.5, y - 2);
    doc.line(130, yTenor - 7, 130, y); // 5
    doc.text(`15`, 135.5, yTenor - 2);
    doc.text(`${total15}`, 135.5, y - 2);
    doc.line(142.5, yTenor - 7, 142.5, y); // 6
    doc.text(`18`, 147.5, yTenor - 2);
    doc.text(`${total18}`, 147.5, y - 2);
    doc.line(155, yTenor - 7, 155, y); // 7
    doc.text(`21`, 160.5, yTenor - 2);
    doc.text(`${total21}`, 160.5, y - 2);
    doc.line(167.5, yTenor - 7, 167.5, y); // 8
    doc.text(`24`, 171.5, yTenor - 2);
    doc.text(`${total24}`, 171.5, y - 2);
    doc.line(180, yTenor - 7, 180, y); // 9
    doc.text(`30`, 184.5, yTenor - 2);
    doc.text(`${total30}`, 184.5, y - 2);
    doc.line(192.5, 55, 192.5, y); // 10
    doc.line(230, 55, 230, y);
    doc.line(280, 55, 280, y);
    doc.line(15, y, 280, y);
    setLoading(false);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography color="#757575">Laporan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Laporan Penjualan Per Surveyor
      </Typography>
      <Divider sx={dividerStyle} />
      <Box sx={showDataWrapper}>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Cabang :
              </Form.Label>
              {user.tipeUser === "OWN/DIREKSI" ? (
                <Col sm="9">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={cabangOptions}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        error={error && cabangInput.length === 0 && true}
                        helperText={
                          error &&
                          cabangInput.length === 0 &&
                          "Cabang harus diisi!"
                        }
                        placeholder="SEMUA CABANG"
                        {...params}
                      />
                    )}
                    onInputChange={(e, value) => {
                      setCabangInput(value.split(" ", 1)[0]);
                    }}
                  />
                </Col>
              ) : (
                <Col sm="9">
                  <Form.Control value={cabangInput} disabled />
                </Col>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Dari Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputDariTgl}
                  onChange={(e) => setInputDariTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputDariTgl === null && true}
                      helperText={
                        error &&
                        inputDariTgl === null &&
                        "Dari Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="3" style={textRight}>
                Sampai Tanggal :
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={inputSampaiTgl}
                  onChange={(e) => setInputSampaiTgl(e)}
                  customInput={
                    <TextField
                      error={error && inputSampaiTgl === null && true}
                      helperText={
                        error &&
                        inputSampaiTgl === null &&
                        "Sampai Tanggal harus diisi!"
                      }
                      sx={{ width: "100%" }}
                      size="small"
                    />
                  }
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Box>
      <Box sx={spacingTop}>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            startIcon={<PrintIcon />}
            onClick={() => {
              if (inputSampaiTgl === null || inputDariTgl === null) {
                setError(true);
              } else downloadPdfRekap();
            }}
          >
            Rekap
          </Button>
          <Button
            color="secondary"
            startIcon={<PrintIcon />}
            onClick={() => {
              if (inputSampaiTgl === null || inputDariTgl === null) {
                setError(true);
              } else downloadPdfRinci();
            }}
          >
            Rinci
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default CariLapPenjualanPerSurveyor;

const subTitleText = {
  fontWeight: "900",
};

const dividerStyle = {
  mt: 2,
};

const spacingTop = {
  mt: 4,
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw"
  // }
};
